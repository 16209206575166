import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Helmet } from 'react-helmet';

import axios from 'axios';
import { getStaticText } from '../../../utils/constants';
import AuthStore from '../../../common/AuthStore';
import DemographicOption from './DemographicOption';
import FlagPole from './Flagpole';
import GeneralSetting from './GeneralSetting';
import ChoseReport from './ChoseReport';
import AdditionalSettings from './AdditionalSettings';
import {
    checkCompareArrLength,
    getFilterSummary,
    getFilterBUSummary,
    updateFilterParams,
    getEkgOptions,
    getFilterInsights,
    manipulateReportData,
    combineFilters,
    getBenchmarkName,
    getBulkFilterArray,
    comparisonFilterArr,
    getResurveyFilterArray,
    getFilterSummaryForBu,
    keepReportOption,
} from '../../../utils/functions';
import './index.scss';
import CompareReports from './CompareReports';
import OutcomeOptions from './OutcomeOptions';
import PracticeOptions from './PracticeOptions';
import Endpoints from '../../../Endpoints';
import AlertModal from '../../../components/AlertModal/AlertModal';
import BuildReport from './BuildReport';
import { BUILD_REPORT_PAYLOAD } from '../constants';

function ReportContent({
    filterIndex,
    updateFilterIndex,
    sideMenu,
    setSideMenu,
    surveySent,
    previousSurveySent,
    surveyCompleted,
    resetFilterIndex,
    demographicDataFetched,
    updateDemographicDataFetched,
    currentMenu,
    setCurrentMenu,
    filters,
    getDemographics,
    getBenchmarks,
    apiParams,
    reportApiParams,
    updateReportApiParams,
    updateFilters,
    leftBenchmarks,
    rightBenchmarks,
    updateRightBenchmarks,
    updateLeftBenchmarks,
    getReport,
    getDataCube,
    apiLoadingCount,
    buDemographics,
    updateBuFilters,
    benchDataFetched,
    projects,
    updateBenchmarkDataFetched,
    defaultSettings,
    inclusionData,
    setTooglePdfReport,
    inclusionFilters,
    iaApiParams,
    getIaBenchmarks,
    updateInclusionFilters,
    scoreData,
    bulkDemographics,
    updateBulkFilters,
    showBulk,
    setBulkToggle,
    getResurveyMapping,
    updateResurveyMapping,
    mappedDemos,
    resurveyDemographics,
    resurveyDemographicDataFetched,
    getResurveyExcel,
    reportError,
    defaultDemographics,
    iaBenchmarkVersions,
    iaBmVersionInUse,
    getBenchmarkVersion,
    setBmVersionInUse,
    // iaProjects,
    getIaProjects,
    showAvgGap,
    setAvgGapToggle,
    comparisonPrevSurveySent,
    getBulkScoreData,
    scoreBulkData,
    setLastMenu,
    resetBulkAssign,
    scoreBulkDataFetched,
    setOrgLevelToggle,
    orgLevelBu,
    targetBenchmarks,
    targetBm,
}) {
    const [benchState, setBenchState] = useState([]);
    const [iaBenchState, setIaBenchState] = useState([]);
    const [buDemoState, setBuDemoState] = useState([]);
    const [demoGraphicState, setDemoState] = useState([]);
    const [ohi4DemoState, setOhi4DemoState] = useState([]);
    const [modal, setModal] = useState(false);
    const percentage = Math.round((surveyCompleted / surveySent) * 100);
    const surversentRR = `${surveySent}||${percentage}%`;
    const { benchmarks = [], demographics = [] } = filters[0] || {};
    const { benchmarks: inclusionBenchmark = [] } = inclusionFilters[0] || {};
    const { ohid: currentOhid, lang: currentLang, year: currentYear, report_type } = apiParams;
    const { options: reportOptions, build_your_report = BUILD_REPORT_PAYLOAD } = reportApiParams;
    const { ReportType } = reportOptions;
    const { report_id = '', userRole } = AuthStore;
    const { qbyq = {} } = inclusionData;
    const [currentBulkDemo, updateBulkDemo] = useState([]);
    const {
        full_inclusion_access = false,
        inclusion_threshold = 10,
        pss_module: pssParam = 0,
        engagement,
        ohi_text,
        ohi4_module,
        ohi4_incModules = [],
        inclusion_resurvey_settings = '',
        survey_version = '',
        lang: defaultlang,
        report_type: surveyType = '',
    } = defaultSettings;
    const staticText = getStaticText(defaultlang);
    const {
        INCLUSION_DEFAULT_BENCHMARK_STR,
        SITE_TEXT,
        CFG_REPORT_ID,
        USER_ROLES,
        NON_PROFIT_SURVEY,
        OHI4_RESIDUAL,
        ING_REPORT_ID,
        AMMEGA_OHI_ID,
        THOMSON_OHI_ID,
        CUSTOM,
        TR_OHID_2024,
    } = staticText;
    const { DYNAMIC_TEXT_INFO, EXCEL_DWNLD_TEXT, QUARTILE_MOVE, REPORT_TEXT, NONE } = SITE_TEXT;
    const { CHOOSE_REPORT_OPTIONS } = DYNAMIC_TEXT_INFO({ ohi_text });
    const isUserNotAdmin =
        userRole && (userRole === USER_ROLES.CLIENT || userRole === USER_ROLES.CST || userRole === USER_ROLES.USER);
    const isCfgClient = !!(isUserNotAdmin && report_id === CFG_REPORT_ID);
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const isIngClient = report_id === ING_REPORT_ID;
    const isAmmegaRpt = currentOhid === AMMEGA_OHI_ID;
    const isThomsonRpt = currentOhid === THOMSON_OHI_ID;
    const isv4 = survey_version === '3_2' || survey_version === '4';
    const additionalSettingsLength = projects.length > 5 ? 5 : projects.length - 1;
    const isQuartileMovement = inclusion_resurvey_settings === QUARTILE_MOVE;
    const [isDemoChecked, setIsDemoChecked] = useState(false);
    const [updateNsizeDemo, setUpdateNsizeDemo] = useState([]);
    // const showBuildReportOp = isNonProfit || parseInt(ReportType, 10) > 3;
    // new
    const isThomsonOhid2024 = currentOhid === TR_OHID_2024;
    // const showBuildReportOp = isNonProfit || parseInt(ReportType, 10) > 3 || isThomsonOhid2024;
    const showBuildReportOp =
        isNonProfit || parseInt(ReportType, 10) > 3 || !isv4 || (isThomsonOhid2024 && ReportType === '1');

    const allowOhi4Modules = useMemo(() => {
        return (
            ohi4_module &&
            ohi4_incModules.filter(({ Name }) => !OHI4_RESIDUAL.includes(Name)).some(({ IsSelected }) => IsSelected)
        );
    }, [ohi4_module, ohi4_incModules]); // eslint-disable-line

    useEffect(() => {
        if (apiLoadingCount === 0 && (!benchmarks.length || !demographics.length)) {
            const { benchmark = [] } = defaultSettings;
            if (!demographics.length) {
                getDemographics({ ohid: currentOhid, lang: currentLang }, 0);
            }
            if (!benchmarks.length) {
                getBenchmarks({ ohid: currentOhid, year: currentYear, lang: defaultlang }, 0, benchmark);
            }
        }
        if (inclusionBenchmark.length < 2) {
            const { benchmark = [] } = defaultSettings;
            getIaBenchmarks({ ohid: currentOhid, year: currentYear, lang: defaultlang }, 0, benchmark, 'inclusion');
            getBenchmarkVersion(
                { ohid: currentOhid, year: currentYear, version: 2, getVersion: 1 },
                0,
                benchmark,
                'inclusion'
            );
            getIaProjects({
                ohid: currentOhid,
                previous_ohid: '1',
            });
        }
        updateReportApiParams({
            ...reportApiParams,
            ohid: currentOhid,
            year: currentYear,
            report_name: '',
            heatmap_demographic: new Map(),
            sort_by: '',
            threshold: 10,
            fill_threshold_with: '',
            need_tornado_chart: false,
            report_id,
            report_type: report_type || 'favorable',
            unmapped_demos: new Map(),
        });
        if (inclusionBenchmark.length) {
            setIaBenchState([inclusionBenchmark]);
            updateReportApiParams({
                ...reportApiParams,
            });
        }
        // eslint-disable-next-line
    }, []);

    const checkBenchMark = benchMark => {
        if (!benchMark.length) {
            return !benchMark.selectedOption === -1;
        }
        return !benchMark.filter(({ selectedOption }) => selectedOption === -1)[0];
    };

    const callFilter = (key, value, filtersApplied = true) => {
        if (ReportType === '4' || ReportType === '6') {
            const withBenchmark = checkBenchMark(iaBenchState[0]);
            const {
                benchmarks: iaBenchMark = INCLUSION_DEFAULT_BENCHMARK_STR,
                exclude_inclusion_gap = '',
            } = iaApiParams;
            const filterArr = combineFilters(iaBenchState, demoGraphicState);
            if (filters.length > 1) {
                filterArr.push(filters[1]);
            }
            return updateInclusionFilters({
                filters: filterArr,
                filtersApplied,
                apiParams: {
                    ...apiParams,
                    benchmarks: iaBenchMark,
                    exclude_inclusion_gap,
                    [key]: value,
                    with_benchmark: withBenchmark,
                },
            });
        }
        if (ReportType === '8') {
            return null;
        }
        return updateFilters({
            filters: combineFilters(benchState, demoGraphicState),
            filtersApplied,
            apiParams: { ...apiParams, [key]: value },
        });
    };

    const handleBenchState = (benchArray, index, del = false) => {
        const benchArr = [...benchState];
        if (!benchArr[index]) {
            benchArr.splice(index, 0, benchArray);
        } else {
            if (del) {
                benchArr.splice(index, 1);
            }
            benchArr[index] = benchArray;
        }
        setBenchState(benchArr);
    };

    const handleIaBenchState = (benchArray, index, del = false) => {
        const benchArr = iaBenchState;
        if (!benchArr[index]) {
            benchArr.splice(index, 0, benchArray);
        } else {
            if (del) {
                benchArr.splice(index, 1);
            }
            benchArr[index] = benchArray;
        }
        setIaBenchState(benchArr);
    };

    const handleDemograhicState = (demoArray, index) => {
        const demoArr = [...demoGraphicState];
        if (!demoArr[index]) {
            demoArr.splice(index, 0, demoArray);
        } else {
            demoArr[index] = demoArray;
        }
        setDemoState(demoArr);
    };

    const handleOhi4DemograhicState = (demoArray, index) => {
        const demoArr = [...ohi4DemoState];
        if (!demoArr[index]) {
            demoArr.splice(index, 0, demoArray);
        } else {
            demoArr[index] = demoArray;
        }
        setOhi4DemoState(demoArr);
    };

    const resetDemoState = () => {
        const cloneDemoState = cloneDeep(demoGraphicState[0]);
        cloneDemoState.forEach(demoVal => {
            const { options = [] } = demoVal;
            options.map(val => {
                val.isSelected = false;
                return val;
            });
            return demoVal;
        });
        return cloneDemoState;
    };

    useEffect(() => {
        if (benchDataFetched && filters.length > 1 && filters[filterIndex].benchmarks) {
            const { benchmarks: updatedBenchData = [] } = filters[filterIndex] || {};
            handleBenchState(updatedBenchData, filterIndex);
            updateBenchmarkDataFetched();
        }
        if (demographicDataFetched && filters.length > 1 && filters[filterIndex].demographics) {
            const { demographics: updatedDemoData = [] } = filters[filterIndex] || {};
            handleDemograhicState(updatedDemoData, filterIndex);
            updateDemographicDataFetched();
        }
        if (!buDemoState.length && buDemographics.length) {
            setBuDemoState(buDemographics);
        }
        if (!benchState.length && benchmarks.length) {
            const benchArr = filters.map(({ benchmarks: bm }) => {
                return bm;
            });
            setBenchState(benchArr);
            handleBenchState(benchmarks, 0);
        }
        if (iaBenchState.length === 1 && inclusionBenchmark.length > 1) {
            const benchArr = filters.map(({ benchmarks: bm }) => {
                return bm;
            });
            setIaBenchState(benchArr);
            handleIaBenchState(inclusionBenchmark, 0);
        }
        if (!demoGraphicState.length && demographics.length) {
            const { bm_filters, benchmarks: bmParams, filters: filterParams } = apiParams;
            updateReportApiParams({
                ...reportApiParams,
                bm_filters,
                benchmarks: bmParams,
                filters: filterParams,
                report_id,
                report_type: report_type || 'favorable',
            });
            const demoArr = filters.map(({ demographics: dm }) => {
                return dm;
            });
            setDemoState(demoArr);
            handleDemograhicState(demographics, 0);
        }
        if (ReportType === '1') {
            const { options } = reportApiParams || {};
            updateReportApiParams({
                ...reportApiParams,
                options: {
                    ...options,
                    org_level_filter: isThomsonOhid2024 ? '1' : orgLevelBu,
                },
            });
        }
        // eslint-disable-next-line
    }, [
        buDemoState,
        demographics,
        benchmarks,
        inclusionBenchmark,
        apiParams,
        benchDataFetched,
        demographicDataFetched,
        orgLevelBu,
    ]);

    const submitDataCubeReport = (updatedReportParams = {}) => {
        const { with_benchmark } = iaApiParams;
        const updatedObj = {
            ...reportApiParams,
            ...updatedReportParams,
            withBenchmark: with_benchmark,
        };
        updateReportApiParams(updatedObj);
        getDataCube(manipulateReportData(updatedObj, ReportType));
    };

    // new
    const submitOpModelReport = (updatedReportParams = {}) => {
        const { with_benchmark } = iaApiParams;
        const updatedObj = {
            ...reportApiParams,
            ...updatedReportParams,
            withBenchmark: with_benchmark,
        };
        updateReportApiParams(updatedObj);
        getReport(manipulateReportData(updatedObj, ReportType));
    };

    const submitExReport = (updatedReportParams = {}) => {
        const { with_benchmark } = iaApiParams;
        const updatedObj = {
            ...reportApiParams,
            ...updatedReportParams,
            withBenchmark: with_benchmark,
        };
        updateReportApiParams(updatedObj);
        getReport(manipulateReportData(updatedObj, ReportType));
    };

    async function getExcelReport(reportName) {
        const { accessToken: Authorization = '' } = AuthStore;
        const { apiBasePath, influencerExcelDwnldPath } = Endpoints;
        const baseUrl = apiBasePath.split('/api')[0];
        axios({
            url: `${baseUrl}${influencerExcelDwnldPath}?ohi_id=${currentOhid}&excel_name=${reportName}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization,
            },
            responseType: 'blob',
        }).then(response => {
            const { status } = response;
            if (status === 200) {
                setModal(true);
            }
        });
    }

    const submitInfluencerReport = (updatedReportParams = {}) => {
        const pss_module = pssParam ? 1 : 0;
        const { heatMapOption = '' } = updatedReportParams || {};
        let heatmap = {};
        if (heatMapOption === NONE) {
            const allHeatmap = new Map();
            demographics.forEach(({ code, label }) => {
                allHeatmap.set(code, label);
            });
            heatmap = {
                heatmap_demographic: allHeatmap,
            };
        }
        const updatedObj = {
            ...reportApiParams,
            ...updatedReportParams,
            ...heatmap,
        };
        updateReportApiParams(updatedObj);
        getReport(
            manipulateReportData(updatedObj, ReportType, {
                surversentRR,
                previousSurveySent,
                pss_module,
                engagement,
            })
        );
    };

    const submitInclusionReport = (updatedReportParams = {}) => {
        const { with_benchmark } = iaApiParams;
        const updatedObj = {
            ...reportApiParams,
            ...updatedReportParams,
            withBenchmark: with_benchmark,
            gap_type: showAvgGap ? 'average' : 'largest',
            quartile_movement: isQuartileMovement,
        };
        const { filters: resurveyFilters } = updateFilterParams(demoGraphicState[1]) || [];
        const { filters: previousFilters } = updateFilterParams(demoGraphicState[2]) || [];
        updateReportApiParams(updatedObj);
        getReport(
            manipulateReportData(updatedObj, ReportType, {
                surversentRR,
                surveyCompleted,
                surveySent,
                inclusion_threshold,
                resurveyFilters,
                previousSurveySent,
                previousFilters,
                comparisonPrevSurveySent,
            })
        );
    };

    const handleCreateReport = newApiParams => {
        const pss_module = pssParam ? 1 : 0;
        if (ReportType === '0') {
            const valueToSend = manipulateReportData(newApiParams, ReportType, {
                surversentRR,
                previousSurveySent,
                pss_module,
                engagement,
                ohi4_incModules,
                cohesionDemographics: ohi4DemoState[1],
            });
            if (showBulk) {
                getBulkFilterArray(currentBulkDemo).forEach(({ filterOption, label: report_name }) => {
                    getReport({
                        ...valueToSend,
                        report_name,
                        filters: [...valueToSend.filters, filterOption],
                    });
                });
            } else {
                getReport(valueToSend);
            }
        } else if (ReportType === '1') {
            const valueToSend = manipulateReportData(newApiParams, ReportType, {
                surversentRR,
                demoGraphicState: demoGraphicState[0],
                buDemographics: buDemoState,
                previousSurveySent,
                pss_module,
                engagement,
                isIngClient,
                cohesionDemographics: ohi4DemoState[1],
                ohi4_incModules,
            });
            if (showBulk) {
                const oldFilters = comparisonFilterArr(defaultDemographics, buDemographics);
                const ingBulk = isIngClient ? { bulk_export: 1 } : {};
                getBulkFilterArray(currentBulkDemo, '', '||').forEach(({ filterOption, label: report_name }) => {
                    const filterSummary = getFilterSummaryForBu([demoGraphicState[0], currentBulkDemo], filterOption);
                    getReport({
                        ...valueToSend,
                        report_name,
                        filters: [...valueToSend.filters, filterOption, ...oldFilters],
                        ...ingBulk,
                        options: {
                            ...valueToSend.options,
                            FilterSummary: filterSummary,
                        },
                    });
                });
            } else {
                getReport(valueToSend);
            }
        } else if (ReportType === '3') {
            const valueToSend = manipulateReportData(newApiParams, ReportType, {
                surversentRR,
                currentYear,
                surveyCompleted,
                surveySent,
                filters,
                previousSurveySent,
                pss_module,
                engagement,
                allFilters: demoGraphicState,
                buDemographics: buDemoState,
                ohi4_incModules,
                cohesionDemographics: ohi4DemoState[1],
            });
            const { additionalSettings, resurveyDownloadType } = newApiParams;
            if (resurveyDownloadType === 'excel') {
                getResurveyExcel(valueToSend);
            } else if (showBulk && additionalSettings.length === 1) {
                const ingBulk = isIngClient || isAmmegaRpt || isThomsonRpt ? { bulk_export: 1 } : {};
                const { FilterSummary = '' } = valueToSend.options || {};
                getResurveyFilterArray(resurveyDemographics, mappedDemos).forEach(
                    ({ filterOption, label: report_name }) => {
                        const filterSummary =
                            FilterSummary +
                            `; ${currentOhid}[${getFilterSummaryForBu([resurveyDemographics], filterOption[0])}]`;
                        getReport({
                            ...valueToSend,
                            report_name,
                            options: {
                                ...valueToSend.options,
                                survey_names: `${report_name}$#$${report_name}`,
                                FilterSummary: filterSummary,
                            },
                            filters: [...valueToSend.filters, ...filterOption],
                            ...ingBulk,
                        });
                    }
                );
            } else if (isAmmegaRpt) {
                getReport({
                    ...valueToSend,
                    options: {
                        ...valueToSend.options,
                        Resurvey_SigVsQuartile: '1',
                        resurvey_custom_delta: '',
                    },
                });
            } else {
                getReport(valueToSend);
            }
        } else if (ReportType === '4' || ReportType === '6') {
            getReport(manipulateReportData(reportApiParams, ReportType, { surversentRR, previousSurveySent }));
        } else if (ReportType === '5') {
            getReport(manipulateReportData(reportApiParams, ReportType));
        } else if (ReportType === '7') {
            getReport(manipulateReportData(reportApiParams, ReportType));
        } else if (ReportType === '9') {
            const valueToSend = manipulateReportData(newApiParams, ReportType, {
                surversentRR,
                previousSurveySent,
                pss_module,
                engagement,
                ohi4_incModules,
                cohesionDemographics: ohi4DemoState[1],
            });
            getReport(valueToSend);
        }
    };

    const submitReport = (updatedReportParams = {}) => {
        setLastMenu(false);
        const report_name = '';
        const filterOption = 'All';
        const demoObj = {
            report_name,
            filterOption,
        };
        const globalFilterObj = {
            report_name,
            filterOption: apiParams.filters.length ? CUSTOM : 'All',
        };
        const buFilterObj = {
            report_name,
            filterOption: checkCompareArrLength(buDemoState) > 0 ? CUSTOM : 'All',
        };
        const dataCubeDemoObj = {
            report_name,
            filterOption: CUSTOM,
        };
        if (currentMenu === 0) {
            const { options } = updatedReportParams;
            const { ReportType: RptType } = options;
            if (isv4) {
                setOhi4DemoState([resetDemoState(), resetDemoState(), resetDemoState()]);
            }
            if (RptType === '0') {
                updatedReportParams.demographicFilters = updatedReportParams.demographicFilters
                    ? updatedReportParams.demographicFilters
                    : [globalFilterObj];
                if (isThomsonRpt) {
                    updatedReportParams.ingDemographicFilters = updatedReportParams.ingDemographicFilters
                        ? updatedReportParams.ingDemographicFilters
                        : [globalFilterObj, buFilterObj];
                }
                // new
                if (isThomsonOhid2024) {
                    updatedReportParams.ingDemographicFilters = updatedReportParams.ingDemographicFilters
                        ? updatedReportParams.ingDemographicFilters
                        : [globalFilterObj, buFilterObj];
                }
            } else if (RptType === '1') {
                updatedReportParams.demographicFilters = updatedReportParams.demographicFilters
                    ? updatedReportParams.demographicFilters
                    : [globalFilterObj, buFilterObj];
                // if (isIngClient || isThomsonRpt) {
                //     updatedReportParams.ingDemographicFilters = updatedReportParams.ingDemographicFilters
                //         ? updatedReportParams.ingDemographicFilters
                //         : [globalFilterObj, buFilterObj];
                // }

                // new
                if (isIngClient || isThomsonRpt || isThomsonOhid2024) {
                    updatedReportParams.ingDemographicFilters = updatedReportParams.ingDemographicFilters
                        ? updatedReportParams.ingDemographicFilters
                        : [globalFilterObj, buFilterObj];
                }
            } else if (RptType === '3' && (isIngClient || isAmmegaRpt || isThomsonRpt)) {
                updatedReportParams.ingDemographicFilters = updatedReportParams.ingDemographicFilters
                    ? updatedReportParams.ingDemographicFilters
                    : [buFilterObj];
            } else if (RptType === '4') {
                setTooglePdfReport(true);
                updatedReportParams.demographicFilters = [globalFilterObj, demoObj, demoObj, demoObj];
                setDemoState([
                    ...demoGraphicState,
                    resetDemoState(),
                    resetDemoState(),
                    resetDemoState(),
                    resetDemoState(),
                    resetDemoState(),
                ]);
            } else if (RptType === '6') {
                setTooglePdfReport(true);
                updatedReportParams.demographicFilters = [globalFilterObj, demoObj, demoObj];
                setDemoState([...demoGraphicState, resetDemoState(), resetDemoState()]);
            } else if (RptType === '5') {
                updatedReportParams.demographicFilters = [globalFilterObj, dataCubeDemoObj];
                setDemoState([...demoGraphicState, resetDemoState()]);
            } else if (RptType === '9') {
                updatedReportParams.demographicFilters = updatedReportParams.demographicFilters
                    ? updatedReportParams.demographicFilters
                    : [globalFilterObj];
            }
        }
        if (currentMenu === 1) {
            callFilter('benchmarks', updatedReportParams.benchmarks);
        }
        if (currentMenu === 2) {
            const demoAdded = [];
            for (let i = 1; i < demoGraphicState.length; i += 1) {
                demoAdded.push(demoObj);
            }
            updatedReportParams.demographicFilters = [globalFilterObj, ...demoAdded];
        }
        if (currentMenu === 4) {
            const ingFlters = ReportType === '3' && (isIngClient || isAmmegaRpt || isThomsonRpt);
            const { filters: updatedFilters } = updateFilterParams(demoGraphicState[0], []);
            callFilter('filters', updatedFilters, !!updatedFilters.length);
            updateBuFilters(buDemoState);
            updatedReportParams.options.BU_FilterSummary =
                ReportType === '1' || ingFlters ? getFilterBUSummary(demoGraphicState[0], buDemoState) : '';
            updatedReportParams.options.FilterSummary = getFilterSummary(demoGraphicState[0]);

            if (['0', '4', '5', '6', '7', '9'].includes(ReportType)) {
                updatedReportParams.filters = updatedFilters;
            }
            if (ReportType === '4') {
                updatedReportParams.ekgOptions =
                    updatedReportParams.demographicFilters[3].filterOption === 'All'
                        ? ''
                        : getEkgOptions(demoGraphicState[3]);
                updatedReportParams.deep_drive_options = getFilterInsights(demoGraphicState[2], []);
                updatedReportParams.gap_on = updateFilterParams(demoGraphicState[1], []).filters.join('$');
                updatedReportParams.demographic_breakdown_summary = updateFilterParams(
                    demoGraphicState[5],
                    []
                ).filters.join('$');
            }
            if (ReportType === '5') {
                const { resource = 'inclusion' } = reportApiParams;
                updatedReportParams.ekgOptions = getEkgOptions(demoGraphicState[1]);
                updatedReportParams.resource = resource;
            }
        }
        if (currentMenu === 5 && ReportType !== '4' && ReportType !== '6') {
            const leftBenchmakName = getBenchmarkName(leftBenchmarks);
            const rightBenchMarkName = getBenchmarkName(rightBenchmarks);
            if (updatedReportParams.bm_filters.length === 1) {
                updatedReportParams.bm_filters = [updatedReportParams.bm_filters[0], updatedReportParams.bm_filters[0]];
                updatedReportParams.options.flagpole_footer = [
                    {
                        bmtext: leftBenchmakName,
                        bmcode: updatedReportParams.bm_filters[0],
                    },
                    {
                        bmtext: rightBenchMarkName,
                        bmcode: updatedReportParams.bm_filters[1],
                    },
                ];
            }
            updatedReportParams.options.leftBenchmarks = leftBenchmakName;
            updatedReportParams.options.rightBenchmarks = rightBenchMarkName;
            if (updatedReportParams) callFilter('bm_filters', updatedReportParams.bm_filters);
        }
        const updatedObj = {
            ...reportApiParams,
            ...updatedReportParams,
            options: { ...reportApiParams.options, ...updatedReportParams.options },
        };
        updateReportApiParams(updatedObj);
        if (currentMenu === 4 && ((ReportType === '4' && !full_inclusion_access) || ReportType === '6')) {
            submitInclusionReport(updatedObj);
            return;
        }
        if (currentMenu === 4 && ReportType === '5') {
            submitDataCubeReport(updatedObj);
            return;
        }
        if (currentMenu === 4 && ReportType === '7') {
            submitExReport(updatedObj);
            return;
        }
        if (currentMenu === 4 && ReportType === '0' && (isNonProfit || isThomsonRpt)) {
            handleCreateReport(updatedObj);
            return;
        }
        if (currentMenu === 4 && ReportType === '0' && surveyType === 'percentile') {
            handleCreateReport(updatedObj);
            return;
        }
        if (
            currentMenu === 4 &&
            (ReportType === '0' || ReportType === '1') &&
            !keepReportOption(build_your_report, 'Benchmarks_Contents')
        ) {
            handleCreateReport(updatedObj);
            return;
        }
        if (isv4) {
            if (currentMenu === 4 && ReportType === '3' && (isIngClient || isAmmegaRpt || isThomsonRpt || !isv4)) {
                handleCreateReport(updatedObj);
                return;
            }
        } else if (currentMenu === 4 && ReportType === '3' && (isIngClient || isAmmegaRpt || isThomsonRpt || isv4)) {
            handleCreateReport(updatedObj);
            return;
        }
        if (currentMenu === 4 && ReportType === '9') {
            submitOpModelReport(updatedObj);
            return;
        }
        if (currentMenu === 4 && ReportType === '1' && (isIngClient || isThomsonRpt || isThomsonOhid2024)) {
            handleCreateReport(updatedObj);
            return;
        }
        if (currentMenu === 4 && ReportType === '1' && surveyType === 'percentile') {
            handleCreateReport(updatedObj);
            return;
        }
        if (ReportType === '8') {
            const { resource = '', report_name: rName = '' } = updatedReportParams;
            if (currentMenu === 4 && resource === 'ohi_influencer_excel') {
                getExcelReport(rName);
                return;
            }
            if (currentMenu === 4 && resource === 'ohi') {
                submitInfluencerReport(updatedObj);
                return;
            }
        }
        if (currentMenu === 1 && [1, 3, 4].includes(sideMenu)) {
            if (showBuildReportOp) {
                setCurrentMenu(currentMenu + 3);
            } else {
                setCurrentMenu(currentMenu + 2);
            }
        } else if (currentMenu === 5 && ['0', '1', '3'].includes(ReportType)) {
            handleCreateReport(updatedObj);
        } else {
            setCurrentMenu(currentMenu + 1);
        }
    };

    const goBack = () => {
        setLastMenu(false);
        if (currentMenu === 3 && [1, 3, 4].includes(sideMenu)) {
            setCurrentMenu(currentMenu - 2);
        } else if (currentMenu === 4 && [1, 3, 4].includes(sideMenu) && showBuildReportOp) {
            setCurrentMenu(currentMenu - 3);
        } else {
            setCurrentMenu(currentMenu - 1);
        }
    };

    const renderSection = () => {
        switch (currentMenu) {
            case 0:
                return (
                    <ChoseReport
                        setSideMenu={setSideMenu}
                        reportApiParams={reportApiParams}
                        submitReport={submitReport}
                        defaultSettings={defaultSettings}
                        isCfgClient={isCfgClient}
                        isIngClient={isIngClient}
                        isAmmegaRpt={isAmmegaRpt}
                        isThomsonRpt={isThomsonRpt}
                        updateReportApiParams={updateReportApiParams}
                    />
                );
            case 1:
                return (
                    <GeneralSetting
                        reportIndex={Number(ReportType)}
                        updateFilterIndex={updateFilterIndex}
                        currentOhid={currentOhid}
                        resetFilterIndex={resetFilterIndex}
                        filters={filters}
                        inclusionFilters={inclusionFilters}
                        getBenchmarks={getBenchmarks}
                        callFilter={callFilter}
                        reportApiParams={reportApiParams}
                        getDemographics={getDemographics}
                        goBack={goBack}
                        submitReport={submitReport}
                        benchState={benchState}
                        handleBenchState={handleBenchState}
                        iaBenchState={iaBenchState}
                        handleIaBenchState={handleIaBenchState}
                        // projects={ReportType === '6' ? iaProjects : projects}
                        projects={ReportType === '6' ? projects : projects}
                        defaultSettings={defaultSettings}
                        updateInclusionFilters={updateFilters}
                        showBulk={showBulk}
                        setBulkToggle={setBulkToggle}
                        bulkDemographics={bulkDemographics}
                        updateBulkFilters={updateBulkFilters}
                        resurveyDemographics={resurveyDemographics}
                        resurveyDemographicDataFetched={resurveyDemographicDataFetched}
                        getResurveyMapping={getResurveyMapping}
                        updateResurveyMapping={updateResurveyMapping}
                        isCfgClient={isCfgClient}
                        isNonProfit={isNonProfit}
                        iaBmVersionInUse={iaBmVersionInUse}
                        iaBenchmarkVersions={iaBenchmarkVersions}
                        setBmVersionInUse={setBmVersionInUse}
                        allowOhi4Modules={allowOhi4Modules}
                        additionalSettingsLength={additionalSettingsLength}
                        apiParams={apiParams}
                        getBulkScoreData={getBulkScoreData}
                        scoreBulkData={scoreBulkData}
                        isDemoChecked={isDemoChecked}
                        setIsDemoChecked={setIsDemoChecked}
                        updateNsizeDemo={updateNsizeDemo}
                        setUpdateNsizeDemo={setUpdateNsizeDemo}
                        resetBulkAssign={resetBulkAssign}
                        scoreBulkDataFetched={scoreBulkDataFetched}
                        targetBenchmarks={targetBenchmarks}
                        targetBm={targetBm}
                    />
                );

            case 2:
                return (
                    <CompareReports
                        defaultSettings={defaultSettings}
                        reportIndex={Number(ReportType)}
                        currentOhid={currentOhid}
                        currentYear={currentYear}
                        reportApiParams={reportApiParams}
                        submitReport={submitReport}
                        goBack={goBack}
                        additionalSettingsLength={additionalSettingsLength}
                    />
                );
            case 3:
                return (
                    <BuildReport
                        defaultSettings={defaultSettings}
                        goBack={goBack}
                        submitReport={submitReport}
                        reportApiParams={reportApiParams}
                        reportIndex={Number(ReportType)}
                    />
                );
            case 4:
                return (
                    <DemographicOption
                        reportIndex={Number(ReportType)}
                        currentOhid={currentOhid}
                        handleDemograhicState={handleDemograhicState}
                        buDemoState={buDemoState}
                        setBuDemoState={setBuDemoState}
                        reportApiParams={reportApiParams}
                        submitReport={submitReport}
                        goBack={goBack}
                        demoGraphicState={demoGraphicState}
                        setDemoState={setDemoState}
                        defaultSettings={defaultSettings}
                        showBulk={showBulk}
                        setBulkToggle={setBulkToggle}
                        bulkDemographics={bulkDemographics}
                        updateBulkFilters={updateBulkFilters}
                        isCfgClient={isCfgClient}
                        isNonProfit={isNonProfit}
                        resurveyMapping={mappedDemos}
                        resurveyDemographics={resurveyDemographics}
                        showAvgGap={showAvgGap}
                        setAvgGapToggle={setAvgGapToggle}
                        isIngClient={isIngClient}
                        isAmmegaRpt={isAmmegaRpt}
                        isThomsonRpt={isThomsonRpt}
                        currentBulkDemo={currentBulkDemo}
                        updateBulkDemo={updateBulkDemo}
                        getBulkScoreData={getBulkScoreData}
                        scoreBulkData={scoreBulkData}
                        apiParams={apiParams}
                        setLastMenu={setLastMenu}
                        setIsDemoChecked={setIsDemoChecked}
                        isDemoChecked={isDemoChecked}
                        updateNsizeDemo={updateNsizeDemo}
                        setUpdateNsizeDemo={setUpdateNsizeDemo}
                        resetBulkAssign={resetBulkAssign}
                        scoreBulkDataFetched={scoreBulkDataFetched}
                        handleOhi4DemograhicState={handleOhi4DemograhicState}
                        ohi4DemoState={ohi4DemoState}
                        setOrgLevelToggle={setOrgLevelToggle}
                        orgLevelBu={orgLevelBu}
                        setCurrentMenu={setCurrentMenu}
                    />
                );
            case 5:
                return sideMenu === 3 ? (
                    <OutcomeOptions
                        defaultSettings={defaultSettings}
                        reportApiParams={reportApiParams}
                        reportIndex={Number(ReportType)}
                        submitReport={submitReport}
                        goBack={goBack}
                        inclusionData={qbyq.records || {}}
                        setLastMenu={setLastMenu}
                    />
                ) : (
                    <FlagPole
                        isCfgClient={isCfgClient}
                        reportError={reportError}
                        scoreData={scoreData}
                        defaultSettings={defaultSettings}
                        reportIndex={Number(ReportType)}
                        submitReport={submitReport}
                        goBack={goBack}
                        reportApiParams={reportApiParams}
                        leftBenchmarks={leftBenchmarks}
                        updateRightBenchmarks={updateRightBenchmarks}
                        updateLeftBenchmarks={updateLeftBenchmarks}
                        rightBenchmarks={rightBenchmarks}
                        isNonProfit={isNonProfit}
                        isIngClient={isIngClient}
                        setLastMenu={setLastMenu}
                    />
                );
            case 6:
                return sideMenu === 3 ? (
                    <PracticeOptions
                        defaultSettings={defaultSettings}
                        reportApiParams={reportApiParams}
                        reportIndex={Number(ReportType)}
                        handleCreateReport={handleCreateReport}
                        goBack={goBack}
                        submitInclusionReport={submitInclusionReport}
                        inclusionData={qbyq.records || {}}
                        setLastMenu={setLastMenu}
                    />
                ) : (
                    <AdditionalSettings
                        scoreData={scoreData}
                        defaultSettings={defaultSettings}
                        reportIndex={Number(ReportType)}
                        handleCreateReport={handleCreateReport}
                        goBack={goBack}
                        isNonProfit={isNonProfit}
                    />
                );

            default:
                return null;
        }
    };

    const { label = '' } = CHOOSE_REPORT_OPTIONS.find(ele => ele.key === ReportType) || {};

    return (
        <Fragment>
            {currentMenu && label ? (
                <Helmet>
                    <title>
                        {REPORT_TEXT} - {label}
                    </title>
                </Helmet>
            ) : null}
            <div className="selectReportWrapper">{renderSection()}</div>
            <AlertModal modal={modal} setModal={setModal} infoText={EXCEL_DWNLD_TEXT} />
        </Fragment>
    );
}

ReportContent.defaultProps = {
    iaApiParams: {},
    getIaBenchmarks: () => {},
    updateInclusionFilters: () => {},
    // iaProjects: [],setLastMenu
    getIaProjects: () => {},
    showAvgGap: false,
    setAvgGapToggle: () => {},
};

ReportContent.propTypes = {
    reportError: PropTypes.string.isRequired,
    currentMenu: PropTypes.number.isRequired,
    setCurrentMenu: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    updateFilters: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    scoreData: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    defaultDemographics: PropTypes.array.isRequired,
    resurveyDemographics: PropTypes.array.isRequired,
    resurveyDemographicDataFetched: PropTypes.bool.isRequired,
    leftBenchmarks: PropTypes.array.isRequired,
    rightBenchmarks: PropTypes.array.isRequired,
    updateLeftBenchmarks: PropTypes.func.isRequired,
    updateRightBenchmarks: PropTypes.func.isRequired,
    updateReportApiParams: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    updateBuFilters: PropTypes.func.isRequired,
    buDemographics: PropTypes.array.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    projects: PropTypes.array.isRequired,
    showBulk: PropTypes.bool.isRequired,
    benchDataFetched: PropTypes.bool.isRequired,
    demographicDataFetched: PropTypes.bool.isRequired,
    updateDemographicDataFetched: PropTypes.func.isRequired,
    updateBenchmarkDataFetched: PropTypes.func.isRequired,
    resetFilterIndex: PropTypes.func.isRequired,
    setSideMenu: PropTypes.func.isRequired,
    sideMenu: PropTypes.number.isRequired,
    filterIndex: PropTypes.number.isRequired,
    updateFilterIndex: PropTypes.func.isRequired,
    surveySent: PropTypes.number.isRequired,
    previousSurveySent: PropTypes.number.isRequired,
    surveyCompleted: PropTypes.number.isRequired,
    setTooglePdfReport: PropTypes.func.isRequired,
    updateBulkFilters: PropTypes.func.isRequired,
    setBulkToggle: PropTypes.func.isRequired,
    getResurveyExcel: PropTypes.func.isRequired,
    getDataCube: PropTypes.func.isRequired,
    inclusionData: PropTypes.object.isRequired,
    inclusionFilters: PropTypes.array.isRequired,
    bulkDemographics: PropTypes.array.isRequired,
    getResurveyMapping: PropTypes.func.isRequired,
    updateResurveyMapping: PropTypes.func.isRequired,
    mappedDemos: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object,
    getIaBenchmarks: PropTypes.func,
    updateInclusionFilters: PropTypes.func,
    getBenchmarkVersion: PropTypes.func.isRequired,
    iaBenchmarkVersions: PropTypes.array.isRequired,
    iaBmVersionInUse: PropTypes.number.isRequired,
    setBmVersionInUse: PropTypes.func.isRequired,
    getIaProjects: PropTypes.func,
    showAvgGap: PropTypes.bool,
    setAvgGapToggle: PropTypes.func,
    comparisonPrevSurveySent: PropTypes.number.isRequired,
    getBulkScoreData: PropTypes.func.isRequired,
    scoreBulkData: PropTypes.object.isRequired,
    setLastMenu: PropTypes.func.isRequired,
    resetBulkAssign: PropTypes.func.isRequired,
    scoreBulkDataFetched: PropTypes.bool.isRequired,
    setOrgLevelToggle: PropTypes.func.isRequired,
    orgLevelBu: PropTypes.string.isRequired,
    targetBenchmarks: PropTypes.array.isRequired,
    targetBm: PropTypes.string.isRequired,
};

export default ReportContent;
