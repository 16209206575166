import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, Tooltip } from '@material-ui/core';
import { cloneDeep } from 'lodash';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import { Toast } from '@mds/mds-reactjs-library';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import { getBulkFilterArray, getFilterArray } from '../../../utils/functions';
import { getStaticText } from '../../../utils/constants';

function BulkPastReport({
    showBulk,
    bulkDemographics,
    apiParams,
    getBulkScoreData,
    scoreBulkData,
    defaultSettings,
    updateBulkDemo,
    currentBulkDemo,
    isOhiReport,
    showBu,
    setShowBu,
    setDemoChecked,
    updateBulkFilters,
    resurveyDemographics,
    resurveyBulkReportType,
    resetBulkAssign,
    scoreBulkDataFetched,
    demoGraphicState,
}) {
    const { lang: defaultLang } = defaultSettings;
    const staticText = getStaticText(defaultLang);
    const { SITE_TEXT } = staticText;
    const {
        INSUFFICIENT_INFO,
        SUFFICIENT_INFO,
        INSUFFICIENT_HEADING,
        INSUFFICIENT_OK,
        THESE_OPTIONS_ARE_ACCURATE_PROCED,
    } = SITE_TEXT || {};
    const [showBulkData, setShowBulkData] = useState([]);
    const [showSufficientData, setShowSufficentData] = useState([]);
    const messageRef = useRef();
    const [allScoreBulkData, setAllScoreBulkData] = useState({});
    const [newAllBulkData, setNewAllBulkData] = useState([]);
    const [bulkData, setBulkData] = useState([]);

    useEffect(() => {
        if (Object.keys(scoreBulkData).length > 0 && showBu) {
            setAllScoreBulkData(scoreBulkData);
        }
        // eslint-disable-next-line
    }, [allScoreBulkData, scoreBulkData]);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    const handleCheckedData = () => {
        const checkedData = showSufficientData;
        const newAllBulkDemographic =
            resurveyBulkReportType === true ? cloneDeep(resurveyDemographics) : cloneDeep(bulkDemographics);
        const newValueChecked = checkedData.map(({ newCodeKey = '' }) => newCodeKey.split(':')[0]);
        const newParentLabel = checkedData.map(({ newCodeKey = '' }) => newCodeKey.split(':')[1]);
        const newParent = newValueChecked.map(value => {
            return newAllBulkDemographic.findIndex(item => item.code === value);
        });

        const newSize = checkedData.map(item => item.newsize);
        const newCode = checkedData.map(item => item.newCodeKey);
        const uncheckedDemo = bulkData;
        const uncheckDemographic = uncheckedDemo.map(({ newCodeKey = '' }) => newCodeKey.split(':')[0]);
        const newChildDemo = uncheckedDemo.map(({ newCodeKey = '' }) => newCodeKey.split(':')[1]);
        const checkedStatus = uncheckedDemo.map(item => item.status);
        const newuncheckedChild = uncheckDemographic.map(value => {
            return newAllBulkDemographic.findIndex(item => item.code === value);
        });
        for (let i = 0; i < newValueChecked.length; i += 1) {
            const currentValue = newParentLabel[i];
            const newParentIndexHere = newParent[i];
            const newCheckedDemo = newAllBulkDemographic[newParentIndexHere].options.findIndex(
                ({ code }) => code === currentValue
            );
            newAllBulkDemographic[newParentIndexHere].options[newCheckedDemo].isSelected = true;
            for (let index = 0; index < newSize.length; index += 1) {
                newAllBulkDemographic[newParentIndexHere].options[newCheckedDemo].newBulkSize = newSize[i];
                newAllBulkDemographic[newParentIndexHere].options[newCheckedDemo].newBulkCode = newCode[i];
            }
        }
        for (let i = 0; i < uncheckDemographic.length; i += 1) {
            const uncheckValue = newChildDemo[i];
            const newUncheckValue = newuncheckedChild[i];
            const newUncheckedDemo = newAllBulkDemographic[newUncheckValue].options.findIndex(
                ({ code }) => code === uncheckValue
            );
            const newStatus = checkedStatus[i];
            if (newStatus !== 'success') {
                newAllBulkDemographic[newUncheckValue].options[newUncheckedDemo].isDisable = true;
            }
        }

        const updatedBenchmarks = cloneDeep(currentBulkDemo);
        const openedBenchmark = updatedBenchmarks.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openedBenchmark !== -1) {
            updatedBenchmarks[openedBenchmark].isOpen = false;
        }
        updateBulkFilters(newAllBulkDemographic, resurveyBulkReportType);
        updateBulkDemo(newAllBulkDemographic);
        setBulkData([...bulkData]);
        setShowSufficentData([]);
        setShowBulkData([]);
        setAllScoreBulkData({});
        setNewAllBulkData([]);
        setShowBu(false);
        setDemoChecked(true);
        resetBulkAssign();
    };

    useEffect(() => {
        const BulkFunc = () => {
            if (showBulkData.length > 0 && showSufficientData.length > 0) {
                setNewAllBulkData([...showBulkData, ...showSufficientData]);
                setBulkData([...showBulkData]);
            } else if (showBulkData.length > 0 && showSufficientData.length <= 0) {
                setNewAllBulkData([...showBulkData]);
                setBulkData([...showBulkData]);
            } else if (showBulkData.length <= 0 && showSufficientData.length > 0) {
                setNewAllBulkData([...showSufficientData]);
                setBulkData([...showSufficientData]);
            } else {
                setNewAllBulkData([]);
                setBulkData([]);
            }
        };

        BulkFunc();
        // eslint-disable-next-line
    }, [showBulkData, showSufficientData]);

    const hasFailed = newAllBulkData.some(item => item.status === 'Failed');

    const addObjectToList = newBulkDemoData => {
        const newBulk = newBulkDemoData;
        const value = newBulk.key;
        const val = newBulk.key ? newBulk.key.split(':')[0].trim() : '';
        const updatedBenchmarks = cloneDeep(currentBulkDemo);

        if (value) {
            const formattingData = updatedBenchmarks
                .filter(item => item.code === val)
                .map(item => {
                    const selectedOptions = item.options.filter(option => option.isSelected);
                    const formattedOptions = selectedOptions
                        .filter(option => `${item.code}:${option.code}` === value)
                        .map(({ label }) => label);

                    const labelItem = {
                        ReportLabel: item.label,
                        ReportChildLabel: formattedOptions.join(','),
                        newCodeKey: value,
                        status: 'Failed',
                    };
                    return labelItem;
                });
            setShowBulkData([...showBulkData, formattingData[0]]);
        }
    };

    const addObjectToChildList = newSuccessVal => {
        const newBulk = newSuccessVal;
        const successVal = newBulk.successKey;
        const bulkVal = newBulk.successKey ? newBulk.successKey.split(':')[0].trim() : '';
        const newBulkSize = newBulk.nsize;

        if (successVal) {
            const updatedBenchmarks = cloneDeep(currentBulkDemo);
            const formattingData = updatedBenchmarks
                .filter(item => item.code === bulkVal)
                .map(item => {
                    const selectedOptions = item.options.filter(option => option.isSelected);
                    const formattedOptions = selectedOptions
                        .filter(option => `${item.code}:${option.code}` === successVal)
                        .map(({ label }) => label);

                    const labelItem = {
                        ReportLabel: item.label,
                        ReportChildLabel: formattedOptions.join(','),
                        status: 'success',
                        newsize: newBulkSize,
                        newCodeKey: successVal,
                    };
                    return labelItem;
                });
            setShowSufficentData([...showSufficientData, formattingData[0]]);
        }
    };
    useEffect(() => {
        if (isOhiReport && showBulk && Object.keys(allScoreBulkData).length > 0) {
            const newBulkDemoData = allScoreBulkData;
            const newFailedData = newBulkDemoData.key || '';
            if (newFailedData) {
                addObjectToList(newBulkDemoData);
            }
            const newSuccessVal = allScoreBulkData.successKey;
            if (newSuccessVal) {
                addObjectToChildList(allScoreBulkData);
            }
            if (messageRef.current) {
                messageRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                });
            }
        }
        // eslint-disable-next-line
    }, [allScoreBulkData, isOhiReport, showBulk]);

    useEffect(() => {
        const updatedBenchmarks = cloneDeep(currentBulkDemo);
        if (newAllBulkData.length > 0) {
            setNewAllBulkData([]);
        }
        if (isOhiReport && showBulk && showBu) {
            getBulkFilterArray(updatedBenchmarks).forEach(({ filterOption }) => {
                const bulkVal = filterOption ? filterOption.split(':')[0].trim() : '';
                const bulkUpdate = updatedBenchmarks.filter(item => item.code === bulkVal).map(item => item.options)[0];
                const checkNsize =
                    bulkUpdate.filter(item => item.newBulkCode === filterOption).map(item => item.newBulkSize)[0] || 0;
                const filterParams = demoGraphicState.length ? getFilterArray(demoGraphicState) : apiParams.filters;
                if (checkNsize < 10) {
                    getBulkScoreData({
                        ...apiParams,
                        filters: [...filterParams, filterOption],
                        filterOption,
                    });
                }
            });
        }
        // eslint-disable-next-line
    }, []);
    return (
        <div>
            {showBu && Object.keys(scoreBulkData).length > 0 && bulkData.length > 0 && isOhiReport && (
                <div className="showBulkSection">
                    <Dialog open={showBu} className="showBulkDialog">
                        {/* <div className="headingContent">
                            <Toast title={INSUFFICIENT_HEADING} type="warning" className="bulkWarning">
                                {INSUFFICIENT_INFO}
                            </Toast>
                        </div> */}
                        {hasFailed ? (
                            <div className="headingContent">
                                <Toast
                                    title={INSUFFICIENT_HEADING}
                                    icon={<WarningIcon style={{ color: '#ffd048' }} />}
                                    className="bulkWarning"
                                >
                                    {INSUFFICIENT_INFO}
                                </Toast>
                            </div>
                        ) : (
                            <div className="headingContent">
                                <Toast title="Bulk report Success" type="success" className="bulkWarning">
                                    All options have sufficient n-size
                                </Toast>
                            </div>
                        )}
                        <DialogContent className="dialogBulkContent">
                            {newAllBulkData.length &&
                                newAllBulkData.map(({ ReportLabel, ReportChildLabel, status }) => {
                                    return (
                                        <div>
                                            <DialogContentText
                                                ref={messageRef}
                                                className="dialogBulkText"
                                                component="span"
                                            >
                                                <div className="reportBulkContent">
                                                    <div>
                                                        <div>
                                                            {status === 'success' ? (
                                                                <CheckCircleIcon
                                                                    style={{ color: 'green', paddingTop: '0.6vh' }}
                                                                />
                                                            ) : (
                                                                <CancelIcon
                                                                    style={{ color: 'red', paddingTop: '0.6vh' }}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <LightTooltip
                                                        className="bulkTooltip"
                                                        title={`${ReportLabel}:${ReportChildLabel}`}
                                                        placement="bottom"
                                                        arrow
                                                        TransitionComponent={Fade}
                                                    >
                                                        <div className="reportLabelContent">
                                                            {`${ReportLabel} : ${ReportChildLabel}`}
                                                        </div>
                                                    </LightTooltip>
                                                </div>
                                            </DialogContentText>
                                        </div>
                                    );
                                })}
                        </DialogContent>
                        {hasFailed || scoreBulkDataFetched ? (
                            <DialogActions className="bulkDialogActions">
                                <div className="bulkInfo">{SUFFICIENT_INFO}</div>
                                <div className="bulkPrimaryBtn" onClick={handleCheckedData}>
                                    {INSUFFICIENT_OK}
                                </div>
                            </DialogActions>
                        ) : (
                            <DialogActions className="bulkDialogActions">
                                <div className="bulkInfo">{THESE_OPTIONS_ARE_ACCURATE_PROCED}</div>
                                <div className="bulkPrimaryBtn" onClick={handleCheckedData}>
                                    {INSUFFICIENT_OK}
                                </div>
                            </DialogActions>
                        )}
                    </Dialog>
                </div>
            )}
        </div>
    );
}

BulkPastReport.propTypes = {
    apiParams: PropTypes.object.isRequired,
    getBulkScoreData: PropTypes.func.isRequired,
    scoreBulkData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    updateBulkDemo: PropTypes.func.isRequired,
    currentBulkDemo: PropTypes.array.isRequired,
    isOhiReport: PropTypes.bool.isRequired,
    showBu: PropTypes.bool.isRequired,
    setShowBu: PropTypes.func.isRequired,
    bulkDemographics: PropTypes.array.isRequired,
    showBulk: PropTypes.bool.isRequired,
    setDemoChecked: PropTypes.func.isRequired,
    updateBulkFilters: PropTypes.func.isRequired,
    resurveyDemographics: PropTypes.array.isRequired,
    resurveyBulkReportType: PropTypes.bool.isRequired,
    resetBulkAssign: PropTypes.func.isRequired,
    scoreBulkDataFetched: PropTypes.bool.isRequired,
    demoGraphicState: PropTypes.array.isRequired,
};
export default BulkPastReport;
