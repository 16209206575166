import colors from '../../sass/colors';
import CompanySurveyImg from '../../containers/Diagnose/assets/CompanyValuesIcon.svg';
import UpArrow from '../../containers/Diagnose/assets/arrow-up.svg';
import DownArrow from '../../containers/Diagnose/assets/arrow-down.svg';
import SquareMinus from '../../containers/Diagnose/assets/minus-square.svg';
import NoSig from '../../containers/Diagnose/assets/no_significance.svg';

export const NONE = 'None';
export const CUSTOM = 'Custom';

export const SITE_TEXT = {
    ERROR_400_MSG:
        "The page you are looking for doesn't exist. You may have mistyped the address or the page may have moved.",
    LOADING: 'Loading',
    BUBBLE_CHART: 'Bubblecharts',
    HEALTH_NAV_LINKS: [
        'Scorecard',
        'Profile',
        'Employee Experience',
        'Practice Ranking',
        'Recipe Scorecard',
        'Demographic',
        'Benchmark',
        'Cohesion',
        'Questions',
        'Text Analytics',
        '',
    ],
    INCLUSION_NAV_LINKS: [
        'Overview',
        'Respondents',
        'Scorecard',
        'Demographic',
        'Questions',
        'Practice Ranking',
        'Resurvey',
    ],
    SURVEY_NAV_LINKS: ['Day to day responses', 'Demographics wise responses', 'Cross tabs'],
    SETTINGS_NAV_LINKS: ['Customization', 'Customize Quartile Colors'],
    LONG_TEXT: '18 characters only',
    REPORT_FILTER: 'Report Filter',
    COMPARISON_FILTER: 'Comparison Filter',
    REPORT_NAV_LINKS: [
        'Choose Report',
        'General Settings',
        'Compare Reports',
        'Build Your report',
        'Demographic Options',
        'Priority Practice & Comparison bubbles',
        'Flagpole Chart & Practice Ranking',
        'Outcome Options',
        'Practice Options',
    ],
    REPORT_NAV_LINKS_V4: [
        'Choose Report',
        'General Settings',
        'Compare Reports',
        'Build Your report',
        'Demographic Options',
        'Comparison bubbles',
        'Flagpole Chart & Practice Ranking',
        'Outcome Options',
        'Practice Options',
    ],
    NPS_NAV_LINKS: ['NPS', 'NPS by Demographic'],
    ENGAGEMENT_NAV_LINKS: ['Additional Focus area', 'Additional Focus area by Demographic'],
    IND_EXP_LINKS: ['Scorecard', 'Questions', 'Heat map', 'Deep Dive'],
    ANALYTICS_NAV_LINKS: [
        'Intro',
        'Overview',
        'Scorecard',
        'Flagpole',
        'Practice Ranking',
        'Differentiator Analysis',
        'Custom Benchmark',
    ],
    ANALYTICS_RANKING_TABS: ['Practice Ranking', 'Differentiator Analysis'],
    DIFFERENTIATOR_ANALYSIS_TABS: ['Quartile Split', 'Median Split'],
    CUSTOM_BM_ANALYSIS_TABS: [
        { tabName: 'Requested Benchmark', index: 1, restrictedAccess: true },
        { tabName: 'Rejected Benchmark', index: 2, restrictedAccess: true },
        { tabName: 'Your Benchmark', index: 0 },
        { tabName: 'Benchmark library', index: 6 },
    ],
    QUARTILE_TAB: ['Quartile Split'],
    ENGAGEMENT_HEADING: 'Additional focus area',
    INCLUSION_OVERVIEW: 'Inclusion Overview',
    ADD_ON: [{ label: 'Company Values', img: CompanySurveyImg, codeN: 0 }],
    ADD_ON_NP: [{ label: 'Text Comments', img: CompanySurveyImg, codeN: 0 }],
    ADD_ON_VIEWS: ['NPS', 'Engagement'],
    ADD_ON_VIEWS_ING: [
        { label: 'NPS', codeN: 1, img: CompanySurveyImg },
        { label: 'Additional focus area', codeN: 2, img: CompanySurveyImg },
    ],
    ADD_ON_VIEWS_TALANX: [{ label: 'Custom BU Report', codeN: 3, img: CompanySurveyImg }],
    NET_PROMOTER_SCORE: 'Net Promoter Score',
    NPS_HEADING: 'Net Promoter Score by Employee',
    CUSTOM_BU_HEADING: 'Custom BU Report',
    NET_PROMOTER_SCORE_DESC: '(Promoters - Detractors)',
    PROMOTERS: 'Promoters',
    PROMOTERS_DESC: 'answer (9 - 10)',
    PASSIVES: 'Passives',
    PASSIVES_DESC: 'answer (7 - 8)',
    DETRACTORS: 'Detractors',
    DETRACTORS_DESC: 'answer (0 - 6)',
    REPORTS_USER_PAGE: 'Reports Users Page',
    ANALYTICS_LANDING_PAGE: 'Ohi Analytics Landing Page',
    INCLUSION_QUESTIONS_BOARD: 'Inclusion Questions Board',
    INCLUSION_RESPONDENTS: 'Inclusion Respondents',
    NPS_NOTE: [
        '1. Questions answered on 11 point scale ranging from 0 = extremely unlikely to 10 = extremely likely',
        '2. Employee net promoters score (eNPS) is calculated by subtracting % of detractors from % of promoters and can range from -100 to 100.',
    ],
    ENGAGEMENT_NOTE: [
        '1. Job engagement: A positive, fulfilling, work related state of mind that is characterised by feelings of dedication, absorption and happiness derived from the job.',
        '2. Organizational engagement: An emotional attachment to ING that is characterized by feeling involvement and enjoyment with working for the organization.',
    ],
    IND_EXP_QUESTION_NOTE: [
        '1. High scores are undesirable indicating the % of employees who responded “Agree” or “Strongly agree” to negative individual experience factors',
        '2. % of “Promotors” who responded “9” or “10” to the question, “How likely are you to recommend working at the organization to friends and relatives?”, answered on an eleven-point scale ranging from 0-Extremely unlikely to 10-Extremely likely',
        '3. rxy is the bivariate correlation between individual responses to Employee Experience Factors and employee-level OHI Scores',
    ],
    OHI4_RESURVEY_NOTE: [
        '1. Statistically significant results had p–values <.05, indicating a high likelihood that an actual change occurred. Statistical significance is based on the number of respondents and size of the point difference',
        '2. Underlying practice questions have changed from 2022 to 2023 i.e., questions have been added or removed based on research and some practices have also been renamed e.g., Employee Conduct used to be called Professional Standards. Going forward the 2023 score should be considered the new baseline',
        '3. Practices which are entirely new concepts introduced in the OHI 4.0 framework have no score is available to the previous year',
        'Practices which are entirely new concepts introduced in the OHI 4.0 framework have no score is available to the previous year',
    ],
    IND_QUESTIONS: [
        {
            title: 'Purpose',
            children: ['connection_to_meaning'],
        },
        {
            title: 'Career',
            children: ['professional_growth', 'equitable_opportunity', 'career_customization'],
        },
        {
            title: 'Well-Being',
            children: ['psychological_safety', 'energy', 'burnout'],
        },
        {
            title: 'Talent retention',
            children: ['job_satisfaction', 'intent_to_leave', 'endorsing_organization_to_others'],
        },
    ],
    IND_QUESTIONS_QUES: [
        {
            title: 'All',
            children: [
                'connection_to_meaning',
                'professional_growth',
                'equitable_opportunity',
                'career_customization',
                'endorsing_organization_to_others',
                'job_satisfaction',
                'intent_to_leave',
                'psychological_safety',
                'energy',
                'burnout',
            ],
        },
        {
            title: 'Purpose',
            children: ['connection_to_meaning'],
        },
        {
            title: 'Career',
            children: ['professional_growth', 'equitable_opportunity', 'career_customization'],
        },
        {
            title: 'Talent Attraction & Retention',
            children: ['endorsing_organization_to_others', 'job_satisfaction', 'intent_to_leave'],
        },
        {
            title: 'Well-Being',
            children: ['psychological_safety', 'energy', 'burnout'],
        },
    ],
    IND_QUES_LEGEND: ['Disagree', 'Neutral', 'Agree', "Don't Know"],
    NPS_SCORE: 'NPS Score',
    NPS_QUES: ' How likely is it that you will recommend being an ING customer to your family friend and colleagues?',
    JOB_ENGAGEMENT: 'Job Engagement',
    ORG_ENGAGEMENT: 'Organisational Engagement',
    PULSE: 'Pulse',
    INCLUSION_SURVEY_RESPONDENTS: '78,351',
    INCLUSION_SURVEY_RESPONDENTS_COUNT: 45,
    DIFF_TEXT: 'Diff',
    ORG_TITLE: 'Organization',
    INCLUSION_SURVEY_RESPONDENTS_V_TEXT: 'v1 March 2022',
    INCLUSION_BENCHMARK_TEXT: 'N= 78,351 | No. surveys: 45 | v1 March 2022',
    DEMO_INCLUSION_TABS: [
        'Score heatmap',
        'Gap heatmap',
        'EKG chart',
        'Resurvey Score heatmap',
        'Resurvey Gap heatmap',
    ],
    SCORECARD_TABS: ['Scorecard', 'Resurvey Scorecard'],
    SCORECARD_GROUP_TEXTS: [
        "Are people aligned around the organization's vision, strategy, culture, and values?",
        'Can employees fulfill their roles with the current capabilities, processes, and motivation level?',
        'How does the organization understand, interact, respond, and adapt to its situation and external environment?',
    ],
    SCORECARD_GROUP_HEAD: ['Alignment', 'Execution', 'Renewal'],
    PRACTICE_CATEGORY_MAP: [
        {
            title: 'Individual Purpose',
            children: ['Connection to Meaning'],
            children_ohi: [
                'N',
                'Common Purpose',
                'Customer Orientation',
                'Social Responsibility',
                'Inspirational Leaders',
                'Financial Incentives',
            ],
            children_ohi_slug: [
                'n',
                'common_purpose',
                'customer_orientation',
                'social_responsibility',
                'inspirational_leaders',
                'financial_incentives',
            ],
        },
        {
            title: 'Career',
            children: ['Professional Growth', 'Equitable Opportunity', 'Career Customization'],
            children_ohi: [
                'N',
                'Talent Development',
                'Career Opportunities',
                'Talent Deployment',
                'People Performance Review',
                'Rewards & Recognition',
            ],
            children_ohi_slug: [
                'n',
                'talent_development',
                'career_opportunities',
                'talent_deployment',
                'people_performance_review',
                'rewards_recognition',
            ],
        },
        {
            title: 'Well-Being',
            children: ['Psychological Safety', 'Energy', 'Burnout'],
            children_ohi: [
                'N',
                'Supportive Leadership',
                'Inclusion & Belonging',
                'Healthy Working Norms',
                'Open & Trusting',
            ],
            children_ohi_slug: [
                'n',
                'supportive_leadership',
                'inclusion_belonging',
                'healthy_working_norms',
                'open_and_trusting',
            ],
        },
        {
            title: 'Talent Attraction & Retention',
            children: ['Job Satisfaction', 'Intent to Leave', 'Endorsing Organization to Others'],
            children_ohi: ['N', 'OHI Score', 'External Orientation', 'Direction'],
        },
    ],
    PRACTICE_CATEGORY_MAP_3_2: [
        {
            title: 'Individual Purpose',
            children: ['Connection to Meaning'],
            children_ohi: [
                'N',
                'Employee Involvement',
                'Customer Focus',
                'Social Responsibility',
                'Inspirational Leaders',
                'Financial Incentives',
            ],
            children_ohi_slug: [
                'n',
                'employee_involvement',
                'customer_focus',
                'inspirational_leaders',
                'financial_incentives',
            ],
        },
        {
            title: 'Career',
            children: ['Professional Growth', 'Equitable Opportunity', 'Career Customization'],
            children_ohi: [
                'N',
                'Talent Development',
                'Career Opportunities',
                'Talent Deployment',
                'People Performance Review',
                'Rewards & Recognition',
            ],
            children_ohi_slug: [
                'n',
                'talent_development',
                'career_opportunities',
                'people_performance_review',
                'rewards_recognition',
            ],
        },
        {
            title: 'Well-Being',
            children: ['Psychological Safety', 'Energy', 'Burnout'],
            children_ohi: [
                'N',
                'Supportive Leadership',
                'Inclusion & Belonging',
                'Healthy Working Norms',
                'Open & Trusting',
            ],
            children_ohi_slug: ['n', 'supportive_leadership', 'open_and_trusting'],
        },
        {
            title: 'Talent Attraction & Retention',
            children: ['Job Satisfaction', 'Intent to Leave', 'Endorsing Organization to Others'],
            children_ohi: ['N', 'OHI Score', 'External Orientation', 'Direction'],
        },
    ],
    PROFILE_VIEW: 'Profile view',
    COMPARISON: 'Comparison',
    MAPPED: 'Mapped',
    SESSION_EXPIRED: 'Your session has expired.',
    LOGIN_BTN: 'Click here to login',
    LOGIN_BTN_TEXT: 'Login',
    LOGIN_TEXT: 'Welcome to',
    LOGIN_REFRESH_TEXT: 'OHI by McKinsey',
    LOGIN_DIFF_USER: 'Login as different user',
    SCORECARD: 'Scorecard',
    BU_HEAD: 'BU Report',
    DRAG_DROP: 'Drag and drop',
    OUTCOMES_TEXT: 'Outcomes & Practices',
    COMPANY_HEAD: 'Company',
    QUESTION_HEAD: 'Questions',
    PROFILE_HEAD: 'Profile',
    PRACTICE_HEAD: 'Practice Ranking',
    RECIPE_HEAD: 'Recipe Scorecard',
    OUTCOME_SUBHEAD: 'outcome questions',
    PRACTICE_SUBHEAD: 'practice questions',
    PRC_QUESTIONS: 'Practice Questions',
    QUESTIONS_LEGEND: ['Disagree', 'Neutral', 'Agree', "Don't Know"],
    SINGLE_QUESTIONS_LEGEND_COLORS: ['#051c2c', '#00a9f4', '#1f40e6'],
    SINGLE_QUESTIONS_LEGEND: ['Detractors(0-6)', 'Passively satisfied(7-8)', 'Promoters(9-10)'],
    QUESTIONS_PRACTICE_LEGEND: ['Infrequently', 'Sometimes', 'Frequently', "Don't Know"],
    QUESTIONS_EX_LEGEND: ['Negative experience', 'Neutral', 'Positive Experience', "Don't Know"],
    SURVEY_LINKS: ['Day to day responses', 'Demographics wise responses', 'Cross tabs'],
    RESPONSE_RATE_CUMULATIVE: 'Response Rate (cumulative)',
    FILTERS: 'Filters',
    FILTERS_MAIN: 'Filters',
    FILTERS_APPLIED: 'Filters Applied',
    BENCHMARKS_APPLIED: 'Benchmarks Applied',
    FILTER_FOR: 'Filter for',
    APPLY_FILTERS: 'Apply Filters',
    RESET_FILTERS: 'Reset Filters',
    VIEW_ALL: 'View all',
    ALL_SELECTED: 'All selected',
    SELECT_ONE: 'Select an Item',
    SELECT_DEMOGRAPHIC: 'Select group',
    SURVEY_TYPE: 'Survey Type',
    SWITCH_VIEW: 'Switch view',
    SURVEY_ID: 'Survey ID',
    SURVEY_PID: 'PID',
    REPORT_ID: 'Report ID',
    SURVEY_NAME: 'Survey Name',
    TARGET_HEADING: 'Global or associated region benchmark is used for associated target setting!',
    TARGET_PARAGRAPH:
        'Practice and outcome targets are set against global or region benchmarks. Anchoring on the global benchmark is recommended as best practice. Regional benchmarks are useful only when local factors affect scores. Sub-benchmarks are more volatile, which makes it difficult to accurately project the point increases required to achieve subsequent quartiles year-over-year.',
    TARGET_OUTCOME: 'The tool will display outcome and practice targets to:',
    TARGET_LIST_1: 'Global benchmark if McKinsey Sector level benchmarks or Specialty/Custom benchmarks are selected.',
    CONTINUE: 'Continue',
    TARGET_SUB_LIST: 'Region benchmark if Country benchmark or Sub-regional benchmark is selected”',
    COACH_TEXT: 'Coach / Creator',
    YR_TEXT: 'Year',
    ACTION_TEXT: 'Action Library',
    EXPLORE_PARAGRAPH: 'Interactive dashboard to explore and visualize OHI GSBD like never before!',
    ACTION_PARAGRAPH: 'Collection of initiatives you can take with clients to drive impact from day one!',
    EXPLORER_TEXT: 'Benchmark Explorer (BMX)',
    EXPLORE: 'Explore',
    EXPLORE_DASHBOARD: 'Explore OHI Dashboards',
    ACTIONS: 'Actions',
    ANALYTICS_TEXT: 'Choose Analytics Report',
    DONE: 'Done',
    NONE,
    NO_ACCESS: "You don't have access to this report for the selected OHI ID, please contact OHI Helpdesk",
    TOTAL_RESPONDENTS: 'Total Respondents',
    TOTAL_RES_TEXT: 'Total Respondents (n) = ',
    TOTAL_RESURVEY_TEXT: 'Total Respondents (n)',
    FREQUENCY_SCALE: 'Frequency scale',
    FILTERS_APPLIED_TEXT: 'As per the filters applied',
    ACROSS_DEMO: 'Across all demographics',
    ON_SURVEY: 'on survey',
    YES: 'Yes',
    OPTION_ONE: 'Option 1',
    OPTION_TWO: 'Option 2',
    SAMPLE_SIZE: 'Sample size',
    N_SIZE: 'N',
    N_SIZE_TEXT: 'N-size',
    N_SIZE_SMALL: 'n-size',
    N_SIZE_MAIN: 'N Size',
    DIS_AGREE: 'Disagree',
    NEUTRAL: 'Neutral',
    AGREE: 'Agree',
    DEMOGRAPHIC: 'Demographic',
    DEMOGRAPHICS: 'Demographics',
    HEALTH_DEMOGRAPHIC: 'Health Demographic',
    DEMOGRAPHICS_SMALL: 'demographics',
    SELECT_MIN_2: 'Can select minimum 2 upto ',
    OUT_OF: ' out of ',
    BENCHMARK_HEAD: 'Benchmark',
    NO_BENCHMARK: 'No benchmarks',
    BOTTOM_DECILE: 'Bottom decile',
    EXCLUDE_GAP_HEAD: 'Gap score calculation demographics',
    EXCLUDE_GAP_SUB_HEAD:
        'You can select demographics below to exclude them from Gap calculations for all Outcomes and practices',
    FILTER_HOVER_TXT:
        'You can now Exclude select demographic groups from largest gap calculations in AND Apply the new IA benchmarks',
    UNDER_DEVELOPMENT_1: 'This page is under development.',
    UNDER_DEVELOPMENT_2: 'Thank you for being patient. We will be back soon.',
    DEMOGRAPHIC_SUMMARY_SUBHEADING: [
        'Choose upto 8 demographic groups for a summary page of demographic doughnuts',
        'Choose upto 5 options for each group, the remaining options will be clubbed in others in the doughnut.',
    ],
    DEMOGRAPHIC_SUMMARY: 'Demographic Groups Summary',
    DIFF_HEAD: 'Difference',
    STATISTICAL_DIFFERENCE: 'Score Difference (with Statistical Significance logic)',
    QUARTILE_DIFFERENCE: 'Score Difference (with Quartile movement logic)',
    CUSTOM_DIFFERENCE: 'Score Difference (with Custom delta logic)',
    VALUE_BASED_DIFFERENCE: 'Score Difference (Value based)',
    GAP_BASED_DIFFERENCE: 'Gap Difference (Value based)',
    BY_TEXT: 'by',
    BUSINESS_TEXT: 'Business Name',
    SEARCH: 'Search',
    TORNADO_LABEL: 'Convert to tornado chart',
    VS_LABEL: ' vs. ',
    LEGEND: 'Legend',
    HIGH_PRACTICES: 'Highlighted Practices',
    RECIPE_TOP: 'Recipe top 10',
    UNDER_EMPHASIZED: 'Underemphasized (< -10 rank)',
    COMPARE_TO_RECIPE: 'Comparable to recipe / overemphasized',
    STRONG_TEXT: 'Strong',
    VERY_STRONG_TEXT: 'Very Strong',
    NO_ALIGNMENT: 'No Alignment',
    OUTCOMES: 'Outcomes',
    PRACTICES: 'Practices',
    LIST_BY_OUTCOMES: 'List by Outcome',
    LIST_BY_PRACTICES: 'List by Practice',
    PRACTICES_SELECT: 'Practice type',
    OUTCOME_SELECT: 'Outcome',
    OUTCOME_TEXT: 'Outcome',
    PRACTICE_TEXT: 'Practice',
    PRACTICE_NAME_VIEW: 'Practice name view',
    OVERVIEW_PRCT_TEXT: 'Outcome and practice overview',
    HEALTH_SCORE: 'Total Health Score',
    TOP: 'Top',
    BOTTOM: 'Bottom',
    THREE: 'Three',
    BY_SCR: 'By Score',
    VIEW_RES: 'View respondents',
    VIEW_SCORE: 'View scorecard',
    VIEW_PRC_RANKING: 'View practice ranking',
    VIEW_PRC: 'View practice',
    VIEW_OCT: 'View outcome',
    VIEW_DEMOGRAPHICS: 'View Demographics',
    VIEW_BY: 'View by',
    INVITED_TEXT: 'Invited for survey',
    COMPLETE_RES_TEXT: 'Complete responses',
    INCOMPLETE_RES_TEXT: 'Incomplete responses',
    DEMO_SURVEY_TOOLTIP: 'Participation rates are only visible for the demographic groups with at least 5 responses.',
    TOTAL_RES: 'No of respondents',
    NUMBER_OF_RESPONDENTS_N: 'Number of respondents (n)',
    RES_HEAD: 'Responses',
    COMPLETE: 'Complete',
    INCOMPLETE: 'Incomplete',
    OVERALL: 'Overall',
    TOTAL: 'Total',
    DAILY_RES_TEXT: 'Daily responses',
    DEMO_RES_TEXT: 'Responses based on ',
    PEOPLE_RES_TEXT: '% of people who responded to the survey',
    PEOPLE_NUM_RES_TEXT: 'Response Rate (cumulative)',
    RES_TO_TEXT: 'Responded to Survey',
    COMMENTS_TEXT: 'Number of comments',
    RESURVEY_TEXT: 'Resurvey',
    HEALTH_BU_DETAILED_BOARD: 'Health Bu-Detailed Board',
    HEALTH_BU_BOARD: 'Health Bu-Board',
    HEALTH_RESURVEY_DETAIL_BOARD: 'Health Re-Survey Detailed Board',
    HEALTH_RESURVEY_BOARD: 'Health Re-Survey Board',
    HEALTH_SCORECARD: 'Health Scorecard',
    RESURVEY_INACTIVE_TEXT:
        'You do not have any access to resurvey data. Please contact your helpdesk team to use this feature',
    COMPARE_BU: 'BU Comparison',
    SELECT_DEFAULT: 'Select year',
    SELECT_YEAR: 'Select the business year to compare',
    BUSINESS_YEAR: 'Business Year',
    DEMOGRAPHIC_GROUP: 'Demographic Group',
    SELECT_YEAR_INCLUSION:
        'Please selct the year below to choose which year’s data you would like to compare with this survey data. Results will be available in the indivdual tabs. ',
    CLOSE_RESURVEY_TEXT: 'Close Resurvey Tabs',
    CONFIRMATION_TEXT:
        'Are you sure you would like to close resurvey? This will remove the tabs that are currently showing the resurvey data.',
    CONFIRMATION_BTN: 'Yes, Close',
    DETAILED_BUTTON: 'View detailed report',
    COMPARE_SURVEY_BUTTON: 'View Comparison on page',
    VIEW_HEATMAP_BUTTON: 'View Comparison Heatmap',
    NO_MAPPED_DEMO:
        'For selected business year mapping does not exist. Please upload demographic mapping before generating comparison heat maps',
    BULK_RESURVEY_ERROR: 'For bulk exports please select only two surveys',
    BULK_RESURVEY_COMP_ERROR: 'For comparison heat maps please select only two surveys',
    COMPANY_RESULTS: 'This year ',
    VIEW_PRACTICE: 'View practice profile',
    VIEW_MORE: 'View more',
    VIEW_LESS: 'View less',
    BENCHMARK: 'Benchmark',
    ORG_DEMOGRAPHIC: 'Organisation Demographic',
    BU_DEMOGRAPHIC: 'BU Demographic',
    COMPANY_RESULTS_TEXT: 'Results',
    BU_RESULTS: 'BU Results',
    COMPANY_RESULTS_DIFF: 'Difference b/w ',
    COMPANY_RESULTS_DIFF_TEXT: ' results and ',
    ADD_YEAR: 'Add another year to compare',
    ADD_ACTION: 'Add New Action',
    CREATE_ACTION: 'Create new OHI Action',
    ACTION_SUBMITTED: 'Submitted Successfully!',
    ACTION_SUBMITTED_TEXT: 'New OHI action details has been submitted sucessfully.',
    OK_TEXT: 'Okay!',
    REPORT_TEXT: 'Report',
    REPORT_VIEW: 'View report',
    REPORTS: 'Reports',
    REPORTS_LIST: 'Report List',
    HOME: 'Home',
    LOG_OUT: 'Logout',
    USER_MNGT: 'User Management',
    USER_ID: 'User Id',
    NAME: 'Name',
    EMAIL: 'Email Address',
    ROLE: 'Role',
    SEARCH_EMAIL_REPORT: 'Search by name or email',
    SELECT_DEMO_GROUP: 'Select Demographic Group',
    N_SIZE_SORT: ['N size: high to low', 'N size: low to high'],
    OVERALL_SORT: ['Overall: high to low', 'Overall: low to high'],
    GENERATE_REPORT: 'Generate Report',
    DOWNLOAD_SAMPLE_REPORT: 'Download Sample Report',
    PAST_REPORT: 'Past Reports',
    THESE_OPTIONS_ARE_ACCURATE_PROCED: 'These options are accurate. proceed.',
    REFRESH_TEXT: 'Refresh',
    REFRESH_REPORTS: 'Refresh Reports',
    DEMOGRAPHIC_OPTIONS: 'Demographic Options',
    DEMOGRAPHIC_FILTERS: 'Demographic Filters',
    DEMOGRAPHIC_THRESHOLD: 'Demographic Threshold',
    CHANGE_DEMOGRAPHIC_THRESHOLD: 'Change Demographic Threshold ?',
    NOT_ALLOWED: 'N/A',
    X_AXIS: 'X- Axis',
    Y_AXIS: 'Y- Axis',
    CHANGE: 'Change',
    INSUFF_RES: 'Insufficient Responses',
    OUTCOME_OPTIONS: 'Outcome Options',
    PRACTICE_OPTIONS: 'Practice Options',
    BUBBLE_CHART_SORTING: 'Bubble Chart Sorting',
    PRACTICE_SCORE_BY_DEMOGRAPHIC: 'EKG Charts',
    DEMOGRAPHIC_INSIGHTS: 'Demographic Deep Dive',
    BUBBLE_CHART_CUSTOMISATION: 'Bubblecharts',
    VIEW_DEMOGRAPHIC_SCORES: 'View Demographic Scores',
    VIEW_SAMPLE_SLIDE: 'View Sample Slide',
    SAMPLE_SLIDE: 'Sample Slide',
    DOWNLOAD_FORMAT: 'Download Format',
    VIEW_SAMPLE: 'View Sample',
    ALL_DEMOGRAPHICS: NONE,
    DEMOGRAPHIC_HEAT_MAP: 'Demographic Heat Map',
    SCORE_HEAT_MAP: 'Score Heat maps',
    SCORE_MAPS: 'Score Heat Map',
    SELECT_ALL: ' Select All',
    ADDITIONAL_SETTINGS: 'Additional Settings',
    CUSTOMISE_EXPORT_REPORT: 'Customize export report',
    CREATE_REPORT: 'Create Report',
    CHOSE_REPORT: 'Choose Report',
    SELECT_REPORT: 'Choose and select the report',
    PRACTICE_RANKING: 'Practice Ranking',
    CHART_PRACTICE_RANKING: 'Flagpole chart & Practice Ranking',
    CHART_PRACTICE_RANKING_BUBBLES: 'Flagpole Practice &  Comparison Bubbles',
    COMPARISON_BUBBLE: 'Comparison Bubbles',
    FLAGPOLE_CHART: 'Practices output on flagpole chart',
    GENERAL_SETTINGS: 'General Settings',
    BULK_REPORT: 'Bulk Report',
    CHOOSE_BULK_UNITS: 'Choose Business Unit',
    COMPARISON_HEATMAP: 'Comparison Heatmap',
    MAIN_GROUP_TEXT: 'Main Group',
    ENABLE_TEXT:
        'Enable this toggle to include additional pages comparing the selected “Main Group” with the “Organization” overall. Note that the n-size for “Organization” is the overall sample size and is independent of any filters applied',
    DEMO_BU_DESC_TEXT:
        'Select the group you want to see deep-dive analysis - usually it is a business unit of function',
    COMPARISION_GROUP: 'Comparison Group',
    COMPARISION_GROUP_DESC:
        ' Select the group that will be a reference point group - group - usually it is the whole organization',
    BULK_REPORT_NOTE:
        'Please check filters carefully on this page. In case of Bulk exports, do not apply a filter for the demographic category, for which you are creating bulk downloads',
    PRACTICE_RANKING_NOTE:
        '​Practice ranks are calculated by standardizing practice scores and ordering them from highest to lowest',
    ENTER_COMPANY_NAME: 'Enter Organisation Name',
    ENTER_ORG_NAME: 'Enter Organization Name',
    LANGUAGE: 'Language',
    COMPARISION_BU_TEXT: 'Comparison BU',
    SELECT_BM_VERSION: 'Select Benchmark version',
    BENCHMARK_QUARTILE_COLOR: 'Benchmark Quartile Coloring',
    BENCHMARK_YEAR: 'Benchmark Year',
    BENCHMARK_VERSION: 'Benchmark Version',
    BENCHMARK_TARGET_SETTING: 'Benchmark applied to pages for points to top/subsequent quartile',
    APPLIED_BENCHMARK_TEXT: 'Reach out to OHI-HelpDesk if you need to change the applied benchmark',
    BENCHMARK_TARGET_SETTING_SUB_INFO:
        'Pages which show scores and targets to achieve top or subsequent quartiles are  benchmarked against Global/Regional benchmarks, even if remainder of the report is benchmarked against a more granular benchmark.',
    BENCHMARK_NA: 'Benchmark not available',
    SCORECARD_NOTE:
        'The median health scores shown are protected Firm IP and should not be published in internal/external publications, nor shared with clients.',
    PERCENT_FAVORABLE: 'OHI scores reported in percent favorable',
    PERCENTILES: 'OHI scores reported in percentiles',
    TARGET_SETTING: 'Target Setting',
    TARGET_HEADER: ['Third Quartile', 'Second Quartile', 'Top Quartile', 'Top decile'],
    TARGET_QUARTILE: 'Target setting represents the points required to achieve next quartile.',
    ACCESS_EXPIRE: 'Access expires',
    OHI_VALADITY: 'Reach out to OHI Helpdesk to extend your validity',
    EE_PERCENT_FAVORABLE: 'EE factors reported in percent favorable',
    EE_PERCENTILE: 'EE factors reported in percent favorable',
    EE_OHI_PERCENT_FAVORABLE: 'OHI scores and EE factors reported in percent favorable',
    EE_OHI_PERCENTILE: 'OHI scores reported in percentiles, EE factors reported in percent favorable',
    ENGLISH: 'English(United States)',
    ENGLISH_CBR: 'English',
    GERMAN_CBR: 'German',
    SET_FLAGPOLE_CHART: 'Set Flagpole Chart',
    DISPLAY_TORNADO_CHART: 'Display Tornado Charts',
    SURVEY_SENT: 'Surveys Sent',
    SURVEY_COMPLETED: 'Surveys Completed',
    COMPLETED: 'Completed',
    INCLUSION_COMPLETED: 'Response Rate',
    RESPONSE_RATE_TEXT: 'Response Rate',
    LAST_UPDATE: 'Last updated',
    USER_SETTINGS: 'User Settings',
    OPTION: 'Options',
    ADDITIONAL_SURVEY: 'Additional Survey',
    PREVIOUS_SURVEY: 'Previous Survey',
    CURRENT_SURVEY: 'Current Survey',
    CURRENT_REPORT_NAME: 'Current Report Name',
    CURRENT_SURVEY_INFO: 'Current Survey Information',
    DATACUBE_TYPE: 'Datacube Type',
    DATACUBE_LEVEL: 'Demographic Level',
    SCORE_DEPTH: 'Score Depth',
    DATACUBE_VERSION: 'Datacube Version',
    REPORT_PLACEHOLDER: 'Enter the name you would like to be used throughout the report',
    SUB_INFO_GENERAL:
        'Select the benchmark you would like to use for quartile coloring throughout the report. Leave as Global to use the Global benchmark or Select other to view other available choices.',
    SUB_INFO_INCLUSION: 'Select the benchmark you would like to use for quartile colouring throughout the report.',
    SUB_INFO_PRACTICE: ' Select the check box below to show tornado charts for practice ranking pages.',
    SUB_INFO:
        "Select the benchmarks you would like to use on the outcome and practice flagpole pages. The client's outcomes and practices will be shown with the difference to the benchmark's median score.",
    BACK: 'Back',
    NEXT: 'Next',
    BUILD_REPORT: 'Build your report',
    BUILD_TEXT:
        'You can customize your report download by removing any of the sections in the list. All sections are selected by default which will produce a standard full deck output',
    INSUFFICIENT_HEADING: 'Bulk report Warning',
    INSUFFICIENT_INFO: 'Some options have insufficient n-size',
    SUFFICIENT_INFO: 'Remove these options and proceed?',
    INSUFFICIENT_OK: 'Yes',
    REMOVE: 'Remove',
    DOWNLOAD_TEXT: 'Download',
    SELECT_DOWNLOAD: 'Select Download',
    DOWNLOAD: 'Download report',
    DOWNLOAD_ALL: 'Download All',
    DOWNLOAD_PPT: 'Download Ppt',
    DOWNLOAD_PDF: 'Download Pdf',
    DOWNLOAD_PPT_V1: 'Download Ppt Version 1',
    DOWNLOAD_PPT_V2: 'Download Ppt Version 2',
    DOWNLOAD_PPT_V3: 'Download Ppt Version 3',
    VERSION_TEXT: 'Version',
    SELECT_OUT_VER: 'Select Outcome Ppt Version',
    INCLUDE_TEXT: 'Include delta heat maps for mapped demographics',
    SELECT_PRTC_VER: 'Select Practice Ppt Version',
    DOWNLOAD_EXCEL: 'Download Excel',
    DOWNLOAD_POWERPOINT: 'Download PowerPoint',
    OUTCOME_PLACEMENT: 'You can customize the outcome placement on the heatmap table',
    SELECT_OUTCOME: 'Select outcome placement',
    STATUS: 'Status',
    ACTION: 'Action',
    DATE: 'Date',
    DATE_TIME: 'Date and time',
    RPT_TYPE: 'Report type',
    RPT_NAME: 'Report name',
    RETRY: 'Retry',
    RESURVEY: 'Resurvey',
    COMPARE: 'Compare',
    TO: 'to',
    NO_REPORTS: 'No reports found',
    HEAT_MAP_NONE: 'If applicable select demographics for EE module',
    HEAT_MAP_SELECT: 'Select the demographic questions that you would like to use in outcome and practice heat maps',
    INCLUSION_HEATMAP: 'Choose the demographic groups that you want to generate the Score Heatmaps for.',
    HEAT_MAP_INCLUSION: 'Choose the demographic groups that you want to generate the Score Heat maps for in "Custom".',
    SCORE_NEW_HEATMAP: 'Choose the demographic groups that you want to generate the Score Heat maps for in "Custom".',
    SCORE_NEW_HEATMAP_2:
        '"None" is selected by default, which means that there will be no Score Heat maps or the other listed pages in your report.',
    HEAT_MAP_INCLUSION_2:
        '"None" is selected by default, which means that there will be no Score Heat maps in your report.',
    HEAT_MAP_INCLUSION_RES:
        'Choose the demographic groups that you want to generate Score Heatmaps for by selecting "Custom".',
    HEAT_MAP_INCLUSION_RES_2:
        'Y-o-Y comparison heat maps are available for mapped demographics only. Contact Helpdesk for y-o-y demographic mapping.',
    INFO_TEXT: 'Y-o-Y comparison heat maps are available for mapped demographics only.',
    NAME_COMPARISON: 'Name Comparison',
    GIVE_NAME_PLACEHOLDER: 'Give a name to comparison',
    ENTER_REPORT_NAME: 'Enter report name',
    SELECT_SURVEY: 'Select Survey',
    SELECT_PRIORITY_PRACTICE: 'Select 15 Priority Practices',
    SELECT_COMPARISON_BUBBLES: 'Select Comparison bubbles',
    OHI4_HEAD: 'Include OHI 4.0 beta questions',
    OHI4_META:
        'Inclue OHI 4.0 beta questions in the report. You will get to select the demographics for the same in the next step.',
    OHI4_OPTIONS_HEAD: 'OHI 4.0 Demographic Options',
    BU_GROUPS: 'BU Comparison Groups',
    BU_BUSINESS_TEXT: 'Select the group you want to see deep-dive analysis (usually it is a business unit of function)',
    BU_ORGANIZATION_TEXT:
        'Select the group that will be a reference point group - group (usually it is the whole organizations)',
    OHI4_OPTIONS_META: 'Please select the eNPS demographics that you want included in the report',
    SELECT_RESURVEY_DOWNLOAD_TYPE: 'Select Report Format',
    ADD_ANOTHER_SURVEY: 'Add Another Survey',
    REPORT_NAME_PLACEHOLDER: 'Enter the name you would like to be used throughout the report',
    SELECT_COMPARE_YEAR: ' Select Compare Year',
    OVERVIEW_TEXT: 'Overview',
    RESPONDENTS_TEXT: 'Respondents',
    TOOLBOX: 'Toolbox',
    NEW_THEME: 'New Theme',
    BUILD_PAGE: 'Build page',
    BU_REPORT_TEXT: 'BU',
    CREATE_TEXT: 'Create',
    OUT_PRT: 'Outcomes and Practices',
    ADD_TEXT: 'Add',
    THEME_NAME: 'Enter Theme Name',
    DESC: 'Description',
    NOTES: 'Notes',
    FEEDBACK_TEXT: 'Please reach out to OHI-Helpdesk team at any point if you have any questions.',
    THEME_PLACE: 'Enter name of max 100 characters',
    NOTES_PLACE: 'Enter your notes here',
    TEXT_PLACE: 'Enter text here',
    SAVED_THEMES: 'Saved patterns',
    NO_PATTERN: 'No pattern found.',
    CREATE_THEME_TEXT: 'Click here to create new theme.',
    CONFIRM: 'CONFIRM',
    CONFIRM_TEXT: 'Confirm',
    DELETE: 'DELETE',
    CANCEL: 'CANCEL',
    CANCEL_TEXT: 'Cancel',
    SAVE_GROUP: 'Save Group',
    DRAG_DROP_HEAD: 'Drag to arrange columns and rows',
    SHOW_SCORE: 'Show score',
    SHOW_SCORES: 'Show scores',
    CUSTOMIZE_COHESION: 'Customize arrangement',
    COHESION_TAB1: 'Units-to-org culture similarities',
    COHESION_TAB2: 'Similarity Matrix',
    CLOSE: 'Close',
    DELETE_PATTERN: 'Delete Pattern ?',
    AVG_GAP: 'Average Gap',
    LARGEST_GAP: 'Largest Gap',
    OVERALL_INCLUSION: 'Overall Inclusion',
    OVERALL_SCORE: 'Overall Score',
    OVERALL_SCORED: 'Overall scored',
    SORT: 'Sort',
    DELETE_MSG:
        'You are about to delete this saved Pattern. You will loose all the data if you choose to delete this pattern.',
    ARE_YOU_SURE: 'Are you sure you want to proceed?',
    SETTINGS: 'Settings',
    BG_COLOR: 'Background color',
    BD_COLOR: 'Border color',
    FONT_COLOR: 'Font color',
    RECIPE_NOTE: 'Recipe Alignment',
    FILTES_CHECK_NOTE: 'Please check the filters applied',
    NOTE: 'Note',
    QUESTION_NOTE: 'All scores indicate % of employees',
    EXCEL_DWNLD_TEXT:
        'Your excel export request has been submitted. You will receive an email shortly with information to download the file.',
    APPLY_SETTINGS: 'Apply',
    BAR_CHART_NOTE:
        "The recipe similarity is the correlation between the client's recipe and the ideal recipe, multiplied by 10",
    BENCH_NOTE:
        'Statistically significant results had p-values < .05 indicating high likelihood that the observed differences are real and not due to chance.',
    FLAGPOLE_NOTE_1:
        'Statistically significant results had p–values <.05, indicating high likelihood that the observed differences are real and not due to chance. Statistical significance is based on the scores observed in the selected sample and the size of the selected sample. The same absolute score difference is more likely to be statistically significant in a large sample compared to a small sample.',
    FLAGPOLE_NOTE_2: 'Practice ranks are based on global percentiles.',
    FLAGPOLE_LINK_TEXT: 'OHI Methodology e-Learning course',
    GSOD_PRAC_NOTE: ['Practice ranks are based on global percentiles', 'Practice ranks are based on z-scores'],
    GSOD_OVERVIEW_NOTE:
        'A minimum of 10 surveys are required to create flagpoles and 25 surveys are required to create color quartiles for sub-benchmarks in OHI client reports',
    // new
    VIEW_ONLINE: 'VIEW ONLINE CLASS ',
    LEARN_MORE: 'To learn more about OHI Methodology, please refer to OHI Methodology e-Learning course',
    LEARN_MORE_LINK:
        'https://mckinseylearning.csod.com/samldefault.aspx?returnUrl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlodetails%2526lo%253d684aef4c-e57c-4d83-bd2a-d2351584451d',
    OVERVIEW_NUMBER_NOTE: 'No deep-dive insights available (n<10)',
    GSOD_PRAC_ANALYSIS: [
        'Top quartile (TQ) and bottom quartile (BQ) organizations reflect the top and bottom 25% of OHI scores in the selected sample',
    ],
    DEMO_NOTE: 'Results not shown for groups of <Threshold participants to protect confidentiality',
    DEMOGRAPHIC_OPTIONS_SUBINFO: 'Select how you would like to display groups of less than ',
    DEMOGRAPHIC_OPTIONS_EX: 'For Employee Experience Report, results will not be shown for groups with n<10',
    DEMOGRAPHIC_OPTIONS_SUBINFO_2: ' in outcome and practice heat maps',
    DATACUBE_OPTIONS_SUBINFO: 'Results will not be shown for groups with n<',
    DATACUBE_OPTIONS_SUBINFO_2: ' by default',
    DATACUBE_NAME_PLACEHOLDER: 'Enter the n value',
    DEMO_OPT_NOTE: thresVal =>
        `Groups of less than ${thresVal} should not be shared with clients to protect confidentiality. Are you sure you wish to show groups < ${thresVal} ? If you would like to deliver these results to your client, please reach out to your ROM for approval.`,
    PREVIOUS: 'Previous',
    COHESION_HEADING: 'Cohesion',
    TXT_ANA_HEADING: 'Text Analytics',
    ADD_ONS: 'Add Ons',
    COHESION_SCORE: 'Cohesion score',
    REPORTS_TIP_TEXT: 'Your reports are ready for download.',
    SWITCH_ACCOUNT: 'Switch account',
    COHESION_SUB_HEADING:
        'Cohesion helps us deep dive beyond what an organisation looks like on average to determine if everyone is experiencing the same way',
    COHESION_LEGENDS_TEXT: [
        {
            type: 'high',
            title: ' > 95 Potentially Too High',
            description: 'Potential for cultural rigidity, lack of diversity and requisite variety in subcultures',
        },
        {
            type: 'moderate',
            title: '75 to 84 Moderate',
            description:
                'Some cultural Cohesion and consistency, but not sufficient to boost organizational performance and health due to divergent ways of working and silos',
        },
        {
            type: 'optimal',
            title: '> 84 to 95 Optimal',
            description:
                'In the sweet spot of high cultural Cohesion without being too high; top quartile performance and health much more likely',
        },
        {
            type: 'low',
            title: '< 75 Low',
            description:
                'Company is, behaviorally speaking, a collection of different cultures and working norms making it more difficult to sustain top quartile health',
        },
    ],
    BU_HEAD_TITLE: ['Organization Health Score', 'BU Health Score', 'Difference'],
    BU_PROJECTS: ['Report Filter', 'Comparison Filter'],
    BAR_CHART_LEGENDS: ['No Alignment', 'Strong', 'Very Strong'],
    COHESION_GRAPH_LEGEND_TEXT: [
        'Benchmarks',
        'Scores >.90(very high)',
        'Scores between .70 and .90(high)',
        'Scores between .50 and .70(moderate)',
        'Scores between .30 and .50(low)',
        'Scores between -.30 and .30(negligible)',
        'Score <-.30(negative)',
    ],
    COHESION_GRAPH_LEGEND_TEXT_OHI4: ['Benchmarks', 'High', 'Moderate', 'Low'],
    SCORECARD_DISCLAIMER: [
        ' Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        ' Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        `Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)`,
    ],
    SCORECARD_RES_DISCLAIMER: [
        "​* Underlying questions changed since past year's survey (i.e., questions added and/or removed). Therefore, past and current year scores cannot be directly compared ",
        '** New practices added since past year’s survey. Therefore, no comparisons available.',
    ],

    SCORECARD_DEMOGRAPHIC_DISCLAIMER: [
        ' Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        ' Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        `Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)`,
        `Results for demographic groups with n < 10 is not reported out`,
        `Gap calculation excludes those that answered 'Prefer not to answer'`,
    ],

    SCORECARD_RESURVEY_VALUE_BASED: [
        'Score comparisons based on magnitude of change only (±1 or more), not statistical significance',
        'Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)',
        'Underlying questions (for Meaningful work, Allyship, Connection Opportunities, Resource Accessibility and Mentorship) changed since past years survey (i.e., questions added and/or removed). Therefore, past and current year scores cannot be directly compared.',
        'New practices (Collective Identity and Sponsorship) added since past year’s survey. Therefore, no comparisons available.',
    ],

    SCORECARD_RESURVEY_QUARTILE_BASED: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)',
        'Underlying questions (for Meaningful work, Allyship, Connection Opportunities, Resource Accessibility and Mentorship) changed since past years survey (i.e., questions added and/or removed). Therefore, past and current year scores cannot be directly compared.',
        'New practices (Collective Identity and Sponsorship) added since past year’s survey. Therefore, no comparisons available.',
    ],

    SCORECARD_RESURVEY_VALUE_BASED_V2: [
        'Score comparisons based on magnitude of change only (±1 or more), not statistical significance',
        'Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)',
    ],

    SCORECARD_RESURVEY_QUARTILE_BASED_V2: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)',
    ],

    RESURVEY_DEMO_VALUE_BASED: [
        'Score comparisons based on magnitude of change only (±1 or more), not statistical significance',
        'Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)',
        'Results for demographic groups with n < 10 is not reported out',
        'Gap calculation excludes those that answered ‘Prefer not to answer',
        'Underlying questions (for Meaningful work, Allyship, Connection Opportunities, Resource Accessibility and Mentorship) changed since past years survey (i.e., questions added and/or removed). Therefore, past and current year scores cannot be directly compared.',
        'New practices (Collective Identity and Sponsorship) added since past year’s survey. Therefore, no comparisons available.',
    ],

    RESURVEY_DEMO_QUARTILE_BASED: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)',
        'Results for demographic groups with n < 10 is not reported out',
        'Gap calculation excludes those that answered ‘Prefer not to answer',
        'Underlying questions (for Meaningful work, Allyship, Connection Opportunities, Resource Accessibility and Mentorship) changed since past years survey (i.e., questions added and/or removed). Therefore, past and current year scores cannot be directly compared.',
        'New practices (Collective Identity and Sponsorship) added since past year’s survey. Therefore, no comparisons available.',
    ],

    RESURVEY_DEMO_VALUE_BASED_V2: [
        'Score comparisons based on magnitude of change only (±1 or more), not statistical significance',
        'Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)',
        'Results for demographic groups with n < 10 is not reported out',
        'Gap calculation excludes those that answered ‘Prefer not to answer',
    ],

    RESURVEY_DEMO_QUARTILE_BASED_V2: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Averages may mask meaningful and important differences in the inclusion experience of various demographics',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Cutoffs for inclusion gaps is based on the distribution of median average gap scores across all outcomes and practices in global Inclusion Assessment database (below typical: <7, typical: 7-14, above typical: >14)',
        'Results for demographic groups with n < 10 is not reported out',
        'Gap calculation excludes those that answered ‘Prefer not to answer',
    ],
    DISCLAIMER: 'Disclaimer',
    RESTRUCTION_TOOLTIP:
        'Threshold criteria restricts analysis to selected samples with 10 orgs in top quartile and bottom quartile of selected sample',
    MEDIAN_RESTRUCTION_TOOLTIP:
        'Threshold criteria restricts analysis to selected samples with 10 orgs in top half and bottom half of selected sample',
    QUESTION_DISCLAIMER:
        'Percentage of respondents who indicated ”Don’t know or Nor Applicable” are not considered in the calculations of Inclusion Outcome/ Practice scores',
    IA_2_QUESTIONS_DISCLAIMER: '4. The question texts may not exactly match with what was asked in the V2.0 survey',
    LEADERS: 'Leaders',
    PEER_TEXT: 'Peer',
    TEAMMATES_TEXT: 'Teammates',
    PEERS: 'Peers/ Teammates',
    INCLUSION: 'Inclusion',
    SHOW_OVERALL: 'Show Overall',
    GAP_SCORE_ARE_LOADING_PLEASE_WAIT: 'Gap Scores are Loading. Please wait.',
    SYSTEMS: 'Systems',
    OUTCOME_SCORE_GAPS: 'Outcome scores and gaps',
    PRACTICE_SCORE_GAPS: 'Practice scores and gaps',
    GAP_CLASSIFICATION_TEXT: 'Gap Classification',
    SCORE_CLASSIFICATION_TEXT: 'Score Classification',
    OHI_ANALYTICS_LOGIN: 'Ohi Analytics Login',
    CHANGE_CLASSIFICATION: [
        {
            label: 'Positive Shift',
            type: 'up',
            color: colors.$green400,
            index: 1,
        },
        {
            label: 'Neutral',
            color: colors.$yellow,
            index: 2,
        },
        {
            label: 'Negative Shift',
            color: colors.$pink,
            type: 'down',
            index: 0,
        },
    ],
    PRACTICE_CLASSIFICATION_TEXT: 'Practice Type :',
    WORKPLACE_QUESTIONS: 'Workplace Outcome questions',
    OUTCOME_QUESTIONS: 'Outcome questions',
    SURVEY_RES: 'Survey Respondents',
    NUMBER_OF_SURVEYS: 'No of surveys',
    PRCT_LARGE_GAP: 'Practices with largest gaps',
    OUTCOME_LARGE_GAP: 'Outcomes with largest gaps',
    DEMOGRAPHICS_LARGE_GAP: 'Demographics with largest gaps',
    PERSONAL_EXP: 'Personal Experience',
    ENT_PERCEPTION: 'Enterprise Perception',
    SIZE_MAIN_TEXT: 'Size',
    WORKPLACE_OUT: 'Workplace Outcome',
    WORKPLACE_OUT_SCORE: 'Workplace Outcome Scores',
    AGGREMENT_LEVEL: 'Agreement Level',
    AGREEMENT_SCALE: 'Agreement scale',
    FREQUENCY_LEVEL_PERCENT: 'Frequency Level in %',
    AGGREMENT_LEVEL_PERCENT: 'Agreement Level in %',
    EXPERIENCE_LEVEL_PERCENT: 'Experience Level in %',
    RESPONSE_PERCENT: 'Response in %',
    SHOW_INCLUSION_GAPS: 'Show Inclusion gaps',
    INCLUSION_SCORECARD: 'Inclusion Scorecard',
    QUESTIONS: 'Questions',
    VALUES_TEXT: 'Company Values',
    TEXT_COMMENTS_TXT: 'Text Comments',
    INC_SCORE_DEMO: 'Inclusion Score for Demographics',
    GAPS_TEXT: 'Gaps',
    BM_DISABLE_TXT:
        'This is disabled as the Inclusion resurvey setting is set to Quartile movement or Statistical significance',
    VALUES_MAIN_TEXT: {
        current: {
            heading: 'Current values',
            para: 'What we are today',
        },
        intersect: {
            heading: 'Current & desired values',
            para: 'What we would like to continue',
        },
        desired: {
            heading: 'Desired values',
            para: 'What we would like to be',
        },
    },
    VALUES_LEGEND_TEXT: [
        'Org health detractors - value with negative correlation to overall health',
        'Org health enhancers - value with strongest positive correlation to overall health',
    ],
    NEGATIVE_PRACTICES: ['Intent to Leave', 'Burnout'],
    DIFFERENCE: [
        { legendText: 'Score Down', color: '#D51F31' },
        { legendText: 'Score Up', color: '#007F26' },
        { legendText: 'Same score', color: '#FFA800' },
    ],
    SOURCE_TEXT: 'Source',
    YEAR: 'Year',
    NUMBER_TEXT: 'n',
    PRACTICE_SCREEN: 'All practices fit to one screen',
    CATEGORY_BASED: 'Category based (Alignment, Execution Renewal)',
    NUMBER_CAP_TEXT: 'N',
    NUMBER_SURVEY_TEXT: 'no. surveys',
    NUMBER_CAP_SURVEY_TEXT: 'No. of surveys',
    RES_PPT_TEXT: 'results',
    ACTION_COMP_HEADER: 'OHI Action Compendium',
    ACTION_COMPENDIUM: 'Action Compendium',
    ACTION_COMP_TEXT:
        "Welcome to the OHI action planning tool! We've compiled a collection of actions you can take with clients to drive impact from day one. Search, compile, and add to them using the options below. You can search by standalone OHI practice or organize and browse by recipe, level, and influence model quadrant.",
    GEN_LIST: 'Generate list',
    DISCLAIMER_TEXT_PART1: 'Results for demographic groups with n',
    DISCLAIMER_TEXT_PART2: 'is not reported out',
    DISCLAIMER_DEMO_PART1: 'Results not shown for group of',
    DISCLAIMER_DEMO_PART2: 'to protect confidentiality',
    DISCLAIMER_DEMO_PART3: '1 Overall score is the average of all 8 questions',
    DISCLAIMER_GAP_TEXT: 'Gap calculation excludes those that answered ‘Prefer not to answer',
    DISCLAIMER_PRACTICE_RANK_TEXT:
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion Practice scores; Inclusion gaps are represented in boxes, all other scores are represented in circles',
    INSIGHT_INFO:
        'Select 2 demographics options within a given demographic group - Demographic option 1 scores are displayed and compared against respective demographic option 2 scores for gap calculation',
    UPDATE_VIEW: 'Change User View',
    UPDATE_VIEW_BUTTON: 'Update View',
    EXCLUDE_DEMO_GROUP_HEADING: 'Exclude Demo group(s) from gap calculations',
    EXCLUDE_DEMO_GROUP_SUB_HEADING: [
        'Select demographics below to exclude them from Gap calculations for all Outcomes and practices.',
        'Note : Straightliners/Speeders, n<threshold groups, pre-selected demos (if any) are already excluded. Please contact Helpdesk to change',
    ],
    INCLUSION_DEMOOPTION_SUB_HEADING: 'For Inclusion Assessment Report, results will not be shown for groups with n<',
    INCLUSION_DEMOOPTION_SUB_HEADING_RESURVEY:
        'For Inclusion Assessment Resurvey Report, results will not be shown for groups with n<',
    INCLUSION_DEMOFILTER_SUB_HEADING: [
        'Select the demographic options that are required for your report.',
        'All demographic options are selected by default which will produce a report for the organization overall.',
    ],
    INCLUSION_PRACTICEBY_DEMO_SUB_HEADING:
        'Select the demographic groups that you want to generate the EKG charts for.',
    DATACUBE_DEMOOPTION_SUB_HEADING:
        'Select minimum 2 and upto 10 demographics for generating L4 or L5 Datacube. Selection of more that 10 demographics will generate a L3 Datacube',
    OUTCOMES_OPTION_SUBHEADING:
        'For deep dive into Inclusion Outcomes, select 2 questions each of the outcome and any two of its practices.',
    PRACTICE_OPTION_SUBHEADING:
        'Select 3 practices from each practice types for a side-by-side comparison of practices with their scores, gaps and %favorable responses of all its questions.',
    DEMO_SCORE_SUBHEADING: 'This heatmap displays the scores for the organization overall',
    BUBBLE_CHART_SUBHEADING: [
        'Choose the demographic groups and its options that you need in your bubble chart.',
        'Note that “demos excluded from gap calculation” cannot be selected',
    ],
    OHI_4_RPT_TEXT: [
        [
            'Choose the demographic groups to generate a response rate breakdown pages',
            '"None" is selected by default, which means that there will be no response rate statistics for any demographic in you report',
        ],
        [
            'Choose the demographic group (and up to 10 options in each group) to generate deep-dive pages of Organizational Cohesion',
            '"None" is selected by default, which means that there will be no Cohesion demographic deep-dive pages in your report',
        ],
        [
            'Choose the demographic groups to generate heat maps pages for Employee Experience Module (Employee Experience heat map, eNPS heat map etc.)',
            '"None" is selected by default, which means that there will be no Employee Experience demographic heat maps',
        ],
    ],
    OHI_4_HEADER: ['Response Rate Demographic', 'Cohesion Demographic', 'Employee Experience Demographic'],
    LOW_THRESHOLD:
        'Report cannot be created since number of respondents in less then minimum threshold. Please check filters.',
    why_important_text: 'Why it’s important',
    what_it_entails: 'What it is',
    ADD_QUES_TEXT: 'Additional Questions',
    EX_JOURNEY_QUES: 'Employee Journey Questions',
    SCORE_TXT: 'Score',
    GAP_TXT: 'Gap',
    BUTTON_TAG: 'Show Highest & Lowest Score',
    GAP_BUTTON_TAG: 'Show Highest Gap',
    GAP_TYPE: 'Gap type',
    ON: 'ON',
    OFF: 'OFF',
    BIG_PPT_ERROR:
        'File is too big, it will be delivered on your email. You will receive the file in next 5-10 minutes.',
    CLOSE_WINDOW_MSG: 'You can close this window, the process will continue in the background',
    ZIP_FILE_MSG: 'Your reports are being downloaded and will be delivered as a zip file.',
    INCL_HEAD: 'Survey Questions',
    INCL_HEAD_META: '% of Favorable Responses',
    INCL_PRAC_RANK_NOTES: [
        'Percentage of respondents who responded with "Agree"/"Strongly agree" for Outcome questions and "Often"/"Almost always" for Practice questions',
        'Percentage of respondents who indicated "Don’t know or Nor Applicable" are not considered in the calculations of Inclusion Outcome/ Practice scores',
    ],
    THRESHOLD_TXT: 'Threshold for reporting',
    UPDATE_TARGET: 'Update Target Count',
    SUBMIT: 'Submit',
    PPT_RES_TEXT: "Percentage of respondents who selected 'agree' or 'strongly agree'",
    PPT_RES_TEXT2: "Percentage of respondents who selected 'often' or 'almost always'",
    PPT_RES_TEXT3: 'Scores represent percentiles',
    COMPENDIUM_OPTION_KEYS: {
        recipe: 'When combined, the OHI practices make up recipes that help inform us what type of organization it is',
        practice:
            'There are 37 practices that comprise organizational health, each has distinct outcomes, survey items, and actions',
        level: 'The estimated complexity of implementing the action (e.g., resources, people, and time)',
        influence_model: [
            'Role Modeling: Behavior of leaders and colleagues',
            'Conviction: Understanding what and why',
            'Capabilities: Skills and abilities',
            'Reinforcement: Processes supporting change',
        ],
    },
    LEVEL_SCORE: 'Level Scores',
    OUT_SCORE: 'Outcome Scores',
    PRC_SCORE: 'Practice Scores',
    DEMO_INCLUSION_LEGENDS: [
        {
            background: colors.$red500,
            title: 'Score below 50',
        },
        {
            background: colors.$orange400,
            title: 'Score between 50-75',
        },
        {
            background: colors.$green700,
            title: 'Score above 75',
        },
    ],
    PR_HEATMAP_LEGENDS: [
        {
            background: colors.$blue500,
            title: 'Top ranking',
        },
        {
            background: colors.$red500,
            title: 'Bottom ranking',
        },
    ],
    DEMOGRAPHICS_GRAPH_CLASSIFICATION: [
        {
            title: 'Very High (80 and more)',
            background: colors.$green600,
        },
        {
            title: 'High (70-79)',
            background: colors.$green500,
        },
        {
            title: 'Moderate (60-69)',
            background: colors.$orange300,
        },
        {
            title: 'Low (50-59)',
            background: colors.$orange200,
        },
        {
            title: 'Very low (less than 50)',
            background: colors.$orange100,
        },
    ],
    PRACTICE_CLASSIFICATION: [
        {
            level: 'Peer',
            title: 'Peers/ Teammates',
        },
        {
            level: 'Leader',
            title: 'Leaders',
        },
        {
            level: 'Systems',
            title: 'Systems',
        },
    ],
    HEADER_LEGENDS: [
        {
            title: 'Disagree/ Strongly Disagree',
            background: colors.$black,
        },
        {
            title: 'Neither agree nor disagree',
            background: colors.$darkBlue400,
        },
        {
            title: 'Agree/ Strongly Agree',
            background: colors.$blue700,
        },
        {
            title: 'Don’t Know or NA',
            background: colors.$white,
        },
    ],
    NA_BG: {
        bgcolor: colors.$white,
        text_color: 'black',
        border_color: 'black',
    },
    SUB_HEADER_LEGENDS: [
        {
            title: 'Seldom/ Almost Never',
            background: colors.$black,
        },
        {
            title: 'Neutral',
            background: colors.$darkBlue400,
        },
        {
            title: 'Often/ Almost always',
            background: colors.$blue700,
        },
        {
            title: 'Don’t Know or NA',
            background: colors.$white,
        },
    ],
    practicesByOutcome: [
        'Allyship',
        'Idea integration',
        'Mutual respect',
        'Peer support',
        'Advocacy',
        'Mentorship',
        'Sponsorship',
        'Participative decision-making',
        'Team building',
        'Impartial treatment',
        'Self-expression',
        'Protective mechanisms',
        'Connection opportunities',
        'Collective identity',
        'Meritocracy',
        'Resource accessibility',
        'Work-life support',
    ],
    pcLegends: {
        Authenticity: 'Allyship, Idea integration',
        Belonging: 'Mutual respect, Advocacy',
        'Meaningful Work': 'Peer support, Mentorship, Sponsorship',
        Acceptance: 'Self-expression, Protective mechanisms, Participative decision-making',
        Camaraderie: 'Connection opportunities, Collective identity, Team building',
        Fairness: 'Meritocracy, Resource accessibility, Work-life support, Impartial treatment',
    },
    outLegends: {
        'Peers/ Teammates': 'Allyship, Idea integration, Mutual respect, Peer support',
        Leader: 'Advocacy, Mentorship, Sponsorship, Participative decision-making, Team building, Impartial treatment',
        Systems:
            'Self-expression, Protective mechanisms, Connection opportunities, Collective identity, Meritocracy, Resource accessibility, Work-life support',
    },
    CHART_TEXT: 'Look for dips across the organisation and demographics',
    FEATURE_MODAL_HEADING: 'New on Inclusion!',
    FEATURE_MODAL_TEXT:
        'These are the snapshots of new features intoduced to inclusion. Swipe the next and previous arrows to scroll though all the images. Press the button below to close this section.',
    FEATURE_MODAL_BTN: 'Got it!',
    RR_SURVEY_OPTIONS: [
        {
            code: 1,
            label: 'Individual',
        },
        {
            code: 2,
            label: 'Combined',
        },
        {
            code: 99,
            label: 'Global',
        },
    ],
    HEADER_NAV: [
        {
            text: 'Home',
            link: '/',
            checkAccess: true,
        },
        {
            text: 'Response Rates',
            link: '/response-rate',
            checkRR: true,
            checkNonProfit: true,
        },
        {
            text: 'Health',
            link: '/diagnose',
            checkHealthAccess: true,
        },
        {
            text: 'Inclusion',
            link: '/inclusion',
            checkIncAccess: true,
        },
        {
            text: 'Report Users',
            link: '/usersList',
            activeLink: true,
            checkUserPermission: true,
            checkTabAccess: true,
        },
    ],

    HEADER_NAV_2: [
        {
            text: 'Home',
            link: '/',
            activeLink: true,
            checkAction: true,
        },
        {
            text: 'Action Library',
            link: '/action',
            checkAction: true,
        },
    ],

    HEADER_NAV_3: [
        {
            text: 'Home',
            link: '/',
            id: 'home',
            activeLink: 'true',
            showBench: true,
        },
        {
            text: 'Benchmark Explorer',
            link: '/gsod',
            id: 'gsod',
            checkGsodAccess: true,
        },
    ],
    SIGNIFICANCE: [
        {
            title: 'Significantly Weaker',
            bg: colors.$pink,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Significantly Stronger',
            bg: colors.$green400,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$yellow200,
            text_color: colors.$white,
            borderWidth: 2,
        },
    ],
    SETTINGS_SIGNIFICANCE: [
        {
            title: 'Weaker',
            bg: colors.$pink,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Stronger',
            bg: colors.$green400,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$yellow200,
            text_color: colors.$white,
            borderWidth: 2,
        },
    ],
    SIGNIFICANCES: [
        {
            title: 'Significantly Weaker',
            bg: colors.$significantlyPink,
            text_color: colors.$white,
            borderWidth: 2,
            sup: 1,
        },
        {
            title: 'Significantly Stronger',
            bg: colors.$significantlyGreen,
            text_color: colors.$white,
            borderWidth: 2,
            sup: 1,
        },
        {
            title: 'Comparable',
            bg: colors.$comparableColor,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'No statistical significance',
            bg: colors.$black,
            text_color: colors.$black,
            borderWidth: 2,
            checkSig: true,
            sup: 2,
        },
    ],
    SIGNIFICANCEv2: [
        {
            title: 'Significantly Weaker',
            bg: colors.$practiceRed,
            text_color: colors.$white,
            practice_color: colors.$practiceRed,
            text: 'weaker',
            borderWidth: 2,
        },
        {
            title: 'Significantly Stronger',
            bg: colors.$practiceGreen,
            text_color: colors.$white,
            practice_color: colors.$practiceGreen,
            text: 'stronger',
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$practiceYellow,
            text_color: colors.$white,
            practice_color: colors.$grey600,
            text: 'comparable',
            borderWidth: 2,
        },
    ],
    GSOD_SIG: [
        {
            title: 'Negative Differentiator',
            bg: colors.$practiceRed,
            text_color: colors.$white,
            practice_color: colors.$practiceRed,
            text: 'weaker',
            borderWidth: 2,
            toolTipText: 'Practice(s) that unhealthy organizations uniquely emphasize',
        },
        {
            title: 'Positive Differentiator',
            bg: colors.$practiceGreen,
            text_color: colors.$white,
            practice_color: colors.$practiceGreen,
            text: 'stronger',
            borderWidth: 2,
            toolTipText: 'Practice(s) that healthy organizations uniquely emphasize',
        },
        {
            title: 'Neutral',
            bg: '#b3b3b3',
            text_color: colors.$white,
            practice_color: colors.$grey600,
            text: 'comparable',
            borderWidth: 2,
            toolTipText: '',
        },
    ],
    QUESTION_SIGNIFICANCE: [
        {
            title: 'Significantly Weaker',
            sup: 1,
            imgSrc: DownArrow,
        },
        {
            title: 'Significantly Stronger',
            sup: 1,
            imgSrc: UpArrow,
        },
        {
            title: 'Comparable',
            imgSrc: SquareMinus,
        },
        {
            title: 'No statistical significance',
            checkSig: true,
            sup: 2,
            imgSrc: NoSig,
        },
    ],
    QUARTILE_MOVE_LEGEND_TXT: [
        'Shifted down quartile(s)',
        'Shifted up quartile(s)',
        'No change in quartile',
        'No score comparison',
    ],
    QUARTILE_MOVE_LEGEND_TXT_IA: [
        'Shifted up to higher quartile',
        'Shifted down to lower quartile',
        'No quartile movement',
        'Comparison not available',
    ],
    CUSTOM_DELTA_LEGEND_TXT: ['Weaker', 'Stronger', 'Comparable', 'Comparison not available'],
    VALUES_BASED_LEGEND_TEXT: [
        'Decrease (-1 or less)',
        '​Increase (+1 or more)',
        'No change (0)',
        '​No score comparison given change in underlying questions*',
    ],
    GAP_LEGEND_TEXT: ['Increase in gap value', 'Decrease in gap value', 'No Change'],
    QUARTILE_MOVE: 'Quartile movement',
    CROSS_TABS: [
        {
            background: colors.$grey800,
            text: '',
        },
        {
            background: colors.$green800,
            text: 'Very high ( > 90% )',
        },
        {
            background: colors.$green900,
            text: 'High ( 70% - 90% )',
        },
        {
            background: colors.$orange500,
            text: 'Moderate ( 40% - 70% )',
        },
        {
            background: colors.$orange600,
            text: 'Low ( < 40% )',
        },
    ],
    QUEUE: ['queue', 'raw_ppt_generating'],
    SORTARR: [
        {
            label: 'Default',
            key: NONE,
        },
        {
            label: 'N size ascending',
            key: 'nsize:asc',
        },
        {
            label: 'N size descending',
            key: 'nsize:desc',
        },
        {
            label: 'Health score ascending',
            key: 'score:asc',
        },
        {
            label: 'Health score descending',
            key: 'score:desc',
        },
    ],
    SORTARR_EE: [
        {
            label: 'Default',
            key: NONE,
        },
        {
            label: 'N size ascending',
            key: 'nsize:asc',
        },
        {
            label: 'N size descending',
            key: 'nsize:desc',
        },
    ],

    CFG_CHOOSE_REPORT_OPTIONS: [
        {
            label: 'BU Report',
            key: '1',
        },
        {
            label: 'Trend Resurvey',
            key: '3',
        },
    ],
    INCLUSION_DEFAULT_BENCHMARK: '1001',
    INCLUSION_DEFAULT_BENCHMARK_STR: '1001:10',
    ADDITIONAL_SETTING_OPTIONS: [
        {
            label: 'Survey Overview',
            key: 'Survey Overview',
        },
        {
            label: 'Health score section',
            key: 'Health score section',
        },
        {
            label: 'Practice prioritization',
            key: 'Practice priortization',
        },
        {
            label: 'Additional Modules',
            key: 'Additional Modules',
        },
        {
            label: 'Results by demographic',
            key: 'Result by demographic',
        },
        {
            label: 'Question by question results',
            key: 'Question by question result',
        },
    ],
    COMPARISON_BUBBLES: [
        {
            label: 'Statistical Significance',
            key: '1',
            list_key: 'statistical significance',
        },
        {
            label: 'Quartile Movement',
            key: '2',
            list_key: 'Quartile movement',
        },
        {
            label: 'Custom Delta',
            key: '3',
            list_key: 'Custom delta',
        },
    ],
    RESURVEY_DOWNLOAD_TYPE: [
        {
            label: 'Create PPT Report',
            key: 'ppt',
        },
        {
            label: 'Download Excel',
            key: 'excel',
        },
    ],
    demoOptionsValue: thresholdVal => {
        return {
            option: [
                {
                    label: `Hide where < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                {
                    label: `Club < ${thresholdVal} into "others" category`,
                    key: '1',
                },
            ],
            adminOption: [
                {
                    label: `Hide where < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                {
                    label: `Show where < ${thresholdVal} but with -`,
                    key: '-',
                },
                {
                    label: 'Show all scores',
                    key: '0',
                },
                {
                    label: `Club < ${thresholdVal} into "others" category`,
                    key: '1',
                },
            ],
            exOption: [
                {
                    label: `Hide where < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
            ],
            inclusionDemoOptions: [
                {
                    label: `Hide where < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
            ],
            dataCubeDemoOptions: [
                {
                    label: `Hide where < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                {
                    label: CUSTOM,
                    key: 'custom',
                },
            ],
            filterArr: [
                {
                    label: 'All respondents',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            heatMap: [
                {
                    label: NONE,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            bubbleChartSort: [
                {
                    label: 'Sort by Nsize Descending',
                    key: 'nsize',
                },
                {
                    label: 'Sort by Score Descending',
                    key: 'score',
                },
            ],
            practiceScoreDemographic: [
                {
                    label: 'All Demographics',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            bubbleChartOptions: [
                {
                    label: 'Set Default',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            excludeOptions: [
                {
                    label: NONE,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            summaryOptions: [
                {
                    label: NONE,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            inclusionGapType: [
                {
                    label: 'Largest gap',
                    key: false,
                },
                {
                    label: 'Average gap',
                    key: true,
                },
            ],
            rrDemographicOptions: [
                {
                    label: NONE,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            practiceRankingOptions: [
                {
                    label: 'List view - no practice scores',
                    key: false,
                },
                { label: 'with practice scores', key: true },
            ],
            empExpDemoOptions: [
                {
                    label: NONE,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            cohesionDemoOptions: [
                {
                    label: NONE,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
        };
    },
    ALL_PPT_TEXT: [
        'List of potential practices to prioritize based on the science behind',
        "No practices missing from the Recipe's top 10",
        'No broken Power Practices',
        'No practices with a gap of >10 to this Recipe',
        'overall health and outcomes vs.',
        'practices vs.',
        'Difference to',
        'overall health vs.',
        'overall health score',
        'outcome profile vs.',
        'outcome profile',
        '1 - Statistically significant results had p-values < .05 indicating high likelihood that an actual change/difference occurred. Statistical significance is based on number of respondents and size of point difference',
        'Overall health and outcomes over time',
        'Practice profile over time',
        'Overall health over time',
        'Step 2: Recipe alignment over time',
        'Outcome profile over time',
        'survey outcome profile1',
        'Practice profile change since last survey with statistical significance change',
        'Inclusion Outcome scores heatmap',
        'Inclusion Practice scores heatmap - Enterprise Perception',
        'Inclusion Practice scores heatmap - Personal Experience',
        'practice profile',
        'TEXT and explanation...',
        'Difference between organization and benchmark median, pp',
        'Key peer comparisons',
        'Question',
        'Question score1',
        'Results not shown for groups of <10 participants to protect confidentiality',
        'Year on year change',
        'Inclusion Outcome scores and gaps',
        'Inclusion Practice scores and gaps',
    ],
    PPT_VALUES: ({ rName = '', buName = '', ques_Meta = '', threshold = 0 } = {}) => ({
        GLOBAL_VALS: {
            health: 'health',
            benchmark: 'Benchmark',
            legendTitle: 'Benchmark',
            txttopD: 'Top Decile',
            txttopQ: 'Top Quartile',
            txt2nd: 'Second Quartile',
            txt3rd: 'Third Quartile',
            txtBottom: 'Bottom Quartile',
            source_txt: 'Source',
            no_of_survey_txt: 'no. surveys',
            legendTitlea: 'Difference',
            Strong: 'Significantly Stronger',
            Comparable: 'Comparable',
            Weak: 'Significantly Weaker',
            Strong_Q: 'Positive quartile or top decile change',
            Comparable_Q: 'No quartile change',
            Weak_Q: 'Negative quartile change',
            legendTitleDifference: 'Comparison',
            '4. Footnote': '1 Percentage of employees',
            text_and_explanation: 'TEXT and explanation',
            strong_txt: 'Strong',
            percentle_specific_text:
                'Percentile scale indicates the % of scores in the benchmark below the score for a given Outcome or Practice. A higher % score indicates relative ranking compared to the benchmark.',
            favourable_specific_text:
                'Scale indicates % of respondents from your organization who selected "agree" or "strongly agree" for outcomes and "often" or "almost always" for management practices.',
        },
        profile: {
            outcomeTitle: `${rName} outcome profile`,
            practiceTitle: `${rName} practice profile`,
        },
        scorecard: {
            title: `${rName} scorecard`,
        },
        practiceBoard: {
            RANK_LABEL: `${rName} rank`,
            RANK_VS_LABEL: `${rName} rank vs. `,
        },
        benchmark: {
            title: `${rName} results`,
        },
        thresholdTip: `Any group smaller than ${threshold} will not be reflected, in order to preserve the confidentiality of respondents.`,
        highlevel: {
            'Title 1': 'High level targets to get each practice to the next quartile',
            txttop: 'Point increase required to achieve next quartile',
        },
        practice_profile_bu_checkme: {
            Title_text: `${rName}'s practice profile vs. ${buName}`,
        },
        questionsMeta: {
            outcome_title: `${ques_Meta} outcome questions1`,
            practice_title: `${ques_Meta} practice questions1`,
            qyq_out_legend: 'Outcomes:',
            qbyq_disagree: 'Disagree',
            qbyq_neutral: 'Neutral',
            qbyq_agree: 'Agree',
            qyq_prac_legend: 'Practices:',
            qbyq_inrequently: 'Infrequently',
            qbyq_sometimes: 'Sometimes',
            qbyq_frequently: 'Frequently',
            '4. Footnote': '1 Percentage of employees',
        },
        comparisonMeta: {
            legendTitleab: 'Comparison in time:',
            txttopb: 'Significantly Stronger<sup>1</sup>',
            txt3rdb: 'Significally weaker<sup>1</sup>',
        },
        Recipe_checkme_1: {
            companyshape: `Difference ${rName} rank vs. recipe rank`,
            '3. Subtitle': 'Steps 3 and 4',
            'AutoShape 250': "Difference in rank order of practices between organization's and recipe",
            'Rectangle 2': 'Recipe',
        },
        Recipe_checkme_second: {
            BPP_text: 'Power practices in bottom quartile',
            G_text: 'Practices with a gap of >10 to this recipe',
            M_text: 'Practices missing from this recipe’s top 10',
            'Rectangle 40': 'Highlighted practices signify those repeated across the boxes of priorities',
            '4. Footnote': 'List of practices is not exhaustive',
            'Rectangle 28a': '1st',
            'Rectangle 28b': '2nd',
            'Rectangle 28c': '3rd',
        },
        cdvalues_checkme: {
            Title_text: `${rName} values`,
            AutoShape250b1: 'Current values',
            AutoShape250c1: 'What we are today',
            AutoShape250d1: 'Current & desired values',
            AutoShape250b2: 'What we would like to continue',
            AutoShape250c2: 'Desired values',
            AutoShape250d2: 'What we would like to be',
            txt3rd1: 'Org health detractors',
            txt3rd2: 'values with negative correlation to overall health',
            txtBottom1: 'Org health enhancers',
            txtBottom2: 'values with strongest positive correlation to overall health',
        },
        resurvey_recipealignment_checkme: {
            '3. Subtitle': 'Step 2',
            'AutoShape 250': 'Recipe similarity based on current relative practice ranking1',
            rcpShp1a: 'Leadership Factory',
            rcpShp1b:
                'Drive performance by developing and deploying strong leaders, supporting them through coaching, formal training and the right growth opportunities',
            rcpShp2a: 'Market Shaper',
            rcpShp2b:
                'Get ahead through innovating at all levels and operationalizing quickly based on deep understanding of both customers and competitors',
            rcpShp3a: 'Execution Edge',
            rcpShp3b:
                'Gain competitive edge by involving all employees in driving performance and innovation, gathering insights and sharing knowledge',
            rcpShp4a: 'Talent/Knowledge Core',
            rcpShp4b: 'Create value by attracting and inspiring top talent',
            '4. Footnote':
                "1 The recipe similarity is the correlation between the client's recipe and the ideal recipe, multiplied by 10",
            rcpScale1: 'No alignment',
            rcpScale2: 'Strong',
            rcpScale3: 'Very strong',
            Title_text: 'Step 2: Recipe alignment over time',
        },
    }),
    DYNAMIC_TEXT_INFO: ({ ohi_text = 'OHI' } = {}) => ({
        OHI_SCORE_TEXT: `${ohi_text} Score`,
        OHI_SCORE_TEXT_PPT: `${ohi_text} Score`,
        REPORT_TYPE: [
            `${ohi_text} Enterprise Report`,
            `${ohi_text} BU Report`,
            'Data Only Report',
            `${ohi_text} Resurvey`,
            'Inclusion Enterprise Report',
            'Datacube',
            'Inclusion Resurvey Report',
            'EX Enterprise Report',
            `${ohi_text} Influencer`,
            'Op Model module excel',
        ],
        CHOOSE_REPORT_OPTIONS: [
            { label: `${ohi_text} Enterprise Report`, key: '0' },
            { label: `${ohi_text} BU Report`, key: '1' },
            { label: `${ohi_text} Resurvey`, key: '3' },
            { label: `${ohi_text} Influencer`, key: '8', checkInfluencer: true },
            { label: 'EX Enterprise Report', key: '7', checkExAccess: true },
            { label: 'Inclusion Enterprise Report', key: '4', checkIncAccess: true },
            {
                label: 'Inclusion Resurvey Report',
                key: '6',
                checkIncAccess: true,
                checkIAResurveyAccess: true,
            },
            { label: 'Datacube', key: '5', checkCubeAccess: true },
            { label: `Op Model module excel`, key: '9' },
        ],

        THOMSON_REPORT_OPTIONS: [
            { label: `${ohi_text} Enterprise Report`, key: '0' },
            { label: `${ohi_text} BU Report`, key: '1' },
            { label: `${ohi_text} Resurvey`, key: '3' },
            { label: 'Datacube', key: '5', checkCubeAccess: true },
        ],

        ING_REPORT_OPTIONS: [
            { label: `${ohi_text} BU Report`, key: '1', checkYear: true },
            { label: `${ohi_text} Resurvey`, key: '3' },
            { label: 'Datacube', key: '5', checkCubeAccess: true },
        ],
        AMMEGA_REPORT_OPTIONS: [{ label: `${ohi_text} Resurvey`, key: '3' }],
    }),
    ROLES_META: {
        'Roles.user': 'User',
        'Roles.cst': 'CST',
        'Roles.client': 'Client',
    },
    COLOR_TEXT: 'Color',
    GREEN_TEXT: 'Green',
    BLUE_TEXT: 'Blue',
    PASTREPORT_TABLE_HEADING: [
        {
            id: 'export_name',
            numeric: false,
            disablePadding: false,
            label: 'Report name',
            width: '',
        },
        {
            id: 'export_type',
            numeric: false,
            disablePadding: false,
            label: 'Report Type',
            width: '16%',
        },
        {
            id: 'created_on',
            numeric: false,
            disablePadding: false,
            label: 'Date',
            width: '16%',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            width: '16%',
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: 'Action',
            width: '16%',
        },
    ],
    DEMOGRAPHIC_EE_THRESHOLD: 'Employee Experience Demographic Threshold',
    CHOOSE_DEMOGRAPHICS: 'Choose Demographics',
    HEALTH_COHESION_BOARD: 'Health Cohesion Board',
    TOP_RANKING: 'Top Ranking',
    MIDDLE_RANKING: 'Middle Ranking',
    BOTTOM_RANKING: 'Bottom Ranking',
    RANK: 'Rank',
    RECIPE_TEXT: 'Recipe',
    DOWNLOADING_TEXT: {
        '1': 'Downloaded',
        '2': 'Downloading',
        '3': 'Your report is getting ready to download',
        '4':
            'You can also view the ready report in the past reports section under the reports call to action on the top navigation bar.',
        '5': 'Download Now',
    },
    ERROR: 'ERROR',
    INFO_MODAL_TEXT: {
        '1': 'How are Inclusion Gaps calculated ?',
        '2': 'Calculate Inclusion scores for every option in each demographic group of survey',
        '3': 'Calculate “largest” gap in Inclusion scores among all options in a demographic group',
        '4': 'Identify the “highest” among all the “largest” gaps across demographic groups',
        '5': 'Repeat above steps for every Outcome and Practice',
        '6': 'How to reduce gap calculations',
        '7': 'Exclude some demographics from gap calculation, and/or',
        '8': 'Apply some demographic filter',
    },
    CLEAR_ALL: 'Clear All',
    GLOBAL: 'Global',
    HEALTH_BENCHMARK_BOARD: 'Health Benchmark Board',
    ANALYTIC_FLAGPOLE_TEXT: {
        '1': 'Analytics Flagpole',
        '2': 'Flagpole',
        '3': 'Quartile coloring is based on comparison of median scores to global benchmark',
        '4': 'Median score',
        '5': 'Median Point Difference',
        '6': 'to Global benchmark',
    },
    ANALYTIC_CUSTOM_BM_TEXT: {
        '1': 'Analytics Custom Benchmark',
        '2': 'Custom Benchmark',
        '3': 'Quartile coloring is based on comparison of median scores to global benchmark',
        '4': 'Median score',
        '5': 'Median Point Difference',
        '6': 'to Global benchmark',
    },
    OVERVIEW_PAGE_TEXT: {
        '1': 'Overview',
        '2': 'OHI database at a glance',
        '3': 'No. of surveys',
        '4': 'No. of Surveys',
        '5': 'No. of Respondents',
        '6': 'Number of Surveys',
    },
    GSOD_FLAGPOLE_NAME: 'Specified Sample’s Median Score',
    GSOD_FLAGPOLE_TEXT: 'Median Score',
    GSOD_FLAGPOLE_TEXT_1: 'Median Point Difference',
    GSOD_FLAGPOLE_TEXT_2: 'to Global benchmark',
    PRACTICE_RANK_PAGE_TEXT: {
        // '1': 'Rank of TQ',
        // '2': 'Rank of BQ',
        '1': 'Healthy organizations rank',
        '2': 'Unhealthy organizations rank',
        '3': 'Difference between ranks',
        '4': 'Overall Practice Ranking',
        '5': 'Analytics Practice-Ranking',
        '6': 'Top quartile, n = ',
        '7': 'Bottom quartile, n = ',
        '8': 'Top and Second quartile, n = ',
        '9': 'Third and Bottom quartile, n = ',
    },
    TOOLTIP_PAGE_TEXT: {
        '1': 'Orgs falling in top quartile of selected sample',
        '2': 'Orgs falling in bottom quartile of selected sample',
        '3': 'Organizations falling in top and second quartile of selected sample',
        '4': 'Organizations falling in third and bottom quartile of selected sample',
    },
    SCORECARD_PAGE_TEXT: {
        '1': 'GSOD Scorecard Board',
        '2': 'Benchmark Scorecard',
        '3': 'Quartile colors based on comparison of median scores to global benchmark',
    },
    ADDONS_PAGE_TEXT: {
        '1': 'Health',
        '2': 'Comments',
        '3': 'Values',
        '4': 'Board',
        '5': 'ING, Diversity, Inclusion and beloging Index',
    },
    EXCEL_TEXT: {
        '1': 'ING, Diversity, Inclusion and beloging Index',
        '2': 'Question text unavailable',
    },
    RESURVEY_BOARD_PAGE: {
        '1': 'Comparison HeatMap Version',
        '2': 'Resurvey Demographic',
        '3': 'HeatMap Board',
    },
    EMPLYEE_EXPEIENCE: 'Employee Experience',
    QUESTION_SCORE: 'Question score',
    IND_EXP_SCORE_PAGE_TEXT_NOTES: 'Ranging from 0=Extremely unlikely to 10=extremely likely',
    EMPLOYEE_NET_PROMOTER_SCORE: 'Employee Net Promoter score',
    EMPLOYEE_FACTORS_POSITIVE: 'Experience Factors (Positive)',
    EMPLOYEE_FACTORS_NEGATIVE: 'Experience Factors (Negative)',
    RESPONDENTS_WHO_RESPONDED: 'Respondents who responded',
    PERCEIVE_THE_ORGANIZATION_AS: 'perceive the organization as',
    HEALTHY: 'Healthy',
    UNHEALTHY: 'Unhealthy',
    EXRTREMELY_LIKELY: 'Extremely likely',
    AGREE_OR_HIGHER: '(Agree) or higher',
    DONT_KNOW_NA: "Don't know/NA",
    OF_TOTAL_NUMBER_OF_RESPONDENTS: 'of total number of respondents',
    AGREEMENT_LEVEL_IN_PERCENTAGE: 'Agreement Level in percentage',
    HEALTH_PRACTICE_RANKING: 'Health Practice-Ranking',
    INCLUSION_PRACTICE_RANKING: 'Inclusion Practice-Ranking',
    BU_COMPARISION: 'BU Comparison',
    SHOW_TOP_12_BU_RANK: 'Show top 12 BU Ranks',
    ORGANISATION_RANK_VS: 'Organisation rank vs',
    RANKING: 'Ranking',
    GRAPH_DIFFERENCE: 'Graph Difference',
    GSOD_RANKING: 'Overall Practice Ranking',
    GSOD_DIFFERENCE: 'Rank Difference',
    HEALTH_PROFILE_PAGE: 'Health Profile Page',
    HEALTH_QUESTIONS_BOARD: 'Health Questions Board',
    EMPLOYEE_EXPERIENCE_QUESTIONS: 'Employee Experience Questions',
    VIEW_EMPLOYEE_EXPERIENCE_QUESTIONS: 'VIEW EMPLOYEE EXPERIENCE QUESTIONS',
    POINT_INCREASE_RREQUIRED_TO_ACHIVE_TOP_QUARTILE: 'Point increase required to achieve top quartile',
    FEEDBACK: 'Feedback',
};

export const FILTER_LIST_RANGE = 7;
export const INCLUSION_DEFAULT_BENCHMARK = '1001';
export const INCLUSION_DEFAULT_BENCHMARK_STR = '1001:10';

export const BPP_LIST = {
    strategic_clarity: 1,
    role_clarity: 2,
    competitive_insights: 3,
    personal_ownership: 4,
};

export const NON_PROFIT_SURVEY = 'non-profit';
export const TR_OHID_2024 = 'OHI14647';
export const OHI4_IND_EXP = 'individual_experience';
export const OHI4_RESIDUAL = ['new_ohi_practices', 'skip_adding_ohi_4_0_questions'];

export const USER_ROLES = {
    SUPER_ADMIN: 'Roles.sa',
    ADMIN: 'Roles.admin',
    COACH: 'Roles.coach',
    CLIENT: 'Roles.client',
    CST: 'Roles.cst',
    USER: 'Roles.user',
};

export const TEXT_COMMENTS_KEYS = ['open_ThreeWords', 'open_Strengths', 'open_Weaknesses', 'open_Thoughts'];

export const API_DUMMY_PARAMS = {
    ohid: 'OHI011119',
    lang: 1033,
    year: 2020,
    threshold: 10,
    bm_filters: ['1001:1'],
    filters: [],
    page: 1,
    columns: 50,
    type: '',
    demographic: '',
    benchmarks: '',
    report_type: '',
    is_top_quartile: 0,
};

export const INC_DUMMY_PARAMS = {
    ...API_DUMMY_PARAMS,
    with_benchmark: true,
    benchmarks: '1001:10',
};

export const ING_REPORT_ID = '102428';
export const AMMEGA_OHI_ID = 'OHI11817';
export const THOMSON_OHI_ID = 'OHI13191';
export const TALX_REPORT_ID = '100068';
export const HYBRID_QUES_KEY = 'q_hybrid';
export const EXCLUDE_QUES_KEY = 'qvitality';
export const ING_PREV_SURVEY_EXC_QUES = ['q_incl_fair', 'q_incl_bel', 'q_incl_div', 'q_incl_psi'];
export const ING_CURR_OHID = 'OHI14084';

export const INCLUSION_BENCHMARK = [
    {
        code: -1,
        label: 'Standardized classification (traffic light colors)',
        options: [{ code: -1, label: 'Standardized classification (traffic light colors)' }],
        selectedOption: '',
    },
];

export const CFG_REPORT_ID = '100054';
export const NPS_CODE = 'AddQ1_AddQ1_1';
export const CURRENT_YEAR = '2024';
export const SLICE_VALUE = 30;
export const BLANCO_DEFAULT_DEMO = 'demo_Engageclassify';
export const BANCO_REPORT_ID = 101194;
export const IND_EXP_SCORE_CLASSIFICATION = [
    {
        title: 'Very High (80+)',
        positiveBg: colors.$indExpColor[1][0],
        negativeBg: colors.$indExpColor[1][1],
        titleNeg: 'Very High (40+)',
        color: '#ffffff',
    },
    {
        title: 'High (70-79)',
        positiveBg: colors.$indExpColor[2][0],
        negativeBg: colors.$indExpColor[2][1],
        color: '#ffffff',
        titleNeg: 'High (30-39)',
    },
    {
        title: 'Moderate (60-69)',
        positiveBg: colors.$indExpColor[3][0],
        negativeBg: colors.$indExpColor[3][1],
        color: '#000000',
        titleNeg: 'Moderate (20-29)',
    },
    {
        title: 'Low (50-59)',
        positiveBg: colors.$indExpColor[4][0],
        negativeBg: colors.$indExpColor[4][1],
        color: '#000000',
        titleNeg: 'Low (10-19)',
    },
    {
        title: 'Very Low (<50)',
        positiveBg: colors.$indExpColor[5][0],
        negativeBg: colors.$indExpColor[5][1],
        color: '#000000',
        titleNeg: 'Very Low (<10)',
    },
];

export const QUARTILE_COLORS = [
    {
        background: '#061F79',
        border: '#FFA800',
        color: '#ffffff',
        title: 'Top decile',
        top_position: '19000',
    },
    {
        background: '#061F79',
        border: '#061F79',
        color: '#ffffff',
        title: 'Top quartile',
        top_position: '23500',
    },

    {
        background: '#2251FF',
        border: '#2251FF',
        color: '#ffffff',
        title: 'Second quartile',
        top_position: '31000',
    },

    {
        background: '#99C4FF',
        border: '#99C4FF',
        color: '#000000',
        title: 'Third quartile',
        top_position: '41000',
    },

    {
        background: '#CCCCCC',
        border: '#CCCCCC',
        color: '#000000',
        title: 'Bottom quartile',
        top_position: '51000',
    },

    {
        background: '#CCCCCC',
        border: '#757575',
        color: '#ffffff',
        title: 'Bottom decile',
        top_position: '19000',
    },
];
export const NEG_IND_QUES = ['burnout', 'intent_to_leave'];
export const QUES_LEGEND_TYPE = {
    burnout: 1,
    intent_to_leave: 1,
    endorsing_organization_to_others: 2,
};

export const PRACTICE_RANKING_FILTER = {
    0: [
        'career_opportunities',
        'challenging_leadership',
        'financial_incentives',
        'financial_management',
        'open_and_trusting',
        'performance_goals',
        'personal_ownership',
        'risk_management',
        'shared_vision',
        'supportive_leadership',
    ],
    1: [
        'business_partnerships',
        'capturing_external_ideas',
        'competitive_insights',
        'customer_orientation',
        'employee_innovation',
        'government_industry_relations',
        'operational_management',
        'operationally_disciplined',
        'role_clarity',
        'top_down_innovation',
    ],
    2: [
        'capturing_external_ideas',
        'common_purpose',
        'consequence_management',
        'employee_innovation',
        'financial_incentives',
        'knowledge_sharing',
        'performance_transparency',
        'rewards_recognition',
        'role_clarity',
        'top_down_innovation',
    ],
    3: [
        'career_opportunities',
        'challenging_leadership',
        'inspirational_leaders',
        'knowledge_sharing',
        'meaningful_values',
        'people_performance_review',
        'personal_ownership',
        'rewards_recognition',
        'talent_acquisition',
        'talent_development',
    ],
};

export const SINGLE_PAGE_EXPORT_PARAMS = ({ BENCHMARK_NAME = 'Global' }) => ({
    others: 0,
    level: 1,
    export_type: 'single_page',
    report_type: 'favorable',
    need_tornado_chart: false,
    resource: 'ohi',
    resurveyDownloadType: 'ppt',
    n_size: 10,
    outcomePptVersion: '1',
    fill_threshold_with: '',
    report_name: 'Single_Page',
    comparison_heatmap: false,
    sort_by: '',
    practicePptVersion: '1',
    ingDemographicFilters: [],
    score_depth: 3,
    summaryOpt: NONE,
    summaryValue: NONE,
    bubbleChartValue: NONE,
    deepDiveValue: NONE,
    rrDemographics: NONE,
    empExpDemographics: NONE,
    cohesionDemographics: NONE,
    summary_demographic: {},
    includeDelta: false,
    unmapped_demos: {},
    bu_comparison_names: '',
    bu_reportname: '',
    options: {
        TopDecileBorder: '#FFD700',
        BU_FilterSummary: '',
        IsPercentile: false,
        TopDecileToggle: true,
        TopQuartileColor: '#002960',
        reportLanguage: '1033',
        SecondQuartileColor: '#128FA7',
        BENCHMARK_NAME,
        SURVEYSENT_RR: '3321||98%',
        FilterSummary: '',
        TopDecileColor: '#002960',
        ReportType: '0',
        BottomQuartileColor: '#D9D9D9',
        flagpole_footer: [
            { bmcode: '', bmtext: '' },
            { bmcode: '', bmtext: '' },
        ],
        ThirdQuartileColor: '#9FB9BD',
        leftBenchmarks: 'Global',
        rightBenchmarks: 'Global',
        pss_module: 0,
        engagement: null,
        non_profit_report_full: '1',
        Resurvey_SigVsQuartile: '1',
        survey_names: '',
        resurvey_custom_delta: '',
        org_level_filter: '0',
    },
    heatmap_demographic: {},
    response_rate_demographic: {},
    employee_experience_demographic: {},
    cohesion_demographic: [],
    ohi4_incModules: [],
    ohi4_incDemographics: {},
});

export const RESURVEY_EXPORT_PARAMS = {
    report_name: 'Resurvey_Scorecard',
    export_type: 'single_page',
    options: {
        Resurvey_footer_BM: '',
        Resurvey_years: '2023',
        Resurvey_SigVsQuartile: '1',
        Resurvey_IsResurvey: 'TRUE',
        reportLanguage: "'1033'",
        ReportType: '3',
        Resurvey_Stats: '2023$3321$3269',
        SURVEYSENT_RR: '3321||98%',
        BU_FilterSummary: '',
        IngBuFilterSummary: '',
        survey_names: 'Prev $#$ Current',
        pss_module: 0,
        engagement: null,
        FilterSummary: '',
        includeDelta: false,
        unmapped_demos: {},
        resurvey_custom_delta: '',
        org_level_filter: '0',
    },
    heatmap_demographic: {},
    comparison_names: 'm',
    PRIORITY_PRAC_ORDER: 'DELETE',
    BENCHMARK_NAME: 'Global',
    practicePptVersion: '1',
    outcomePptVersion: '1',
    comparison_heatmap: true,
    bu_comparison_names: '',
    bu_reportname: '',
    bu_Filters: [],
    sort_by: '',
    others: 0,
};

export const PRACTICE_RANKING_DESCRIPTION = {
    'Recipe A (Leadership Factory) alignment':
        'Drive performance by developing and deploying strong leaders, supported through coaching, training, and growth opportunities',
    'Recipe B (Market Shaper) alignment':
        'Get ahead by innovating at all levels and gaining a deep understanding of both customers and competitors',
    'Recipe C (Execution Edge) alignment':
        'Get a competitive edge by involving all employees in driving performance and innovation, gathering insights, and sharing knowledge',
    'Recipe D (Talent/Knowledge Core) alignment': 'Create value by attracting and inspiring top talent',
};

export const QUESTION_REPLACEMENTS = {
    QbyQ_coordination_control_4_0: ['pss_1_QbyQ_coordination_control_4_0', 'pss_2_QbyQ_coordination_control_4_0'],
    QbyQ_capabilities_4_0: ['pss_QbyQ_capabilities_4_0'],
    QbyQ_accountability_4_0: ['pss_QbyQ_accountability_4_0'],
    QbyQ_work_environment_4_0: ['pss_QbyQ_work_environment_4_0'],
};

export const PROFILE_REPLACEMENTS = {
    practice_profile_checkme_4_0: ['pss_practice_profile_checkme_4_0'],
    Practice_rank_alignment_4_0: ['pss_Practice_rank_alignment_4_0'],
    Practice_rank_execution_4_0: ['pss_Practice_rank_execution_4_0'],
    Practice_rank_renewal_4_0: ['pss_Practice_rank_renewal_4_0'],
};

export const SCORECARD_REPLACEMENTS = {
    Scorecard_4_0: ['pss_Scorecard_4_0'],
};

export const ING_OVERALL_HEALTH = [
    {
        name: `OVERALL_HEALTHGRP04`,
        is_delete: true,
    },
    {
        name: 'OVERALL_HEALTHOVAL03',
        is_delete: true,
    },
];
