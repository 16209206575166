import React, { useState } from 'react';
import { Select, MenuItem, Button, TextField } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import c from 'classnames';

import { cloneDeep } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import { getStaticText } from '../../utils/constants';
import { NO_BM_STYLE } from './constants';

function Customization({ data, errorInfo, updateData, resetError, defaultSettings }) {
    const { customSettings: oldCustomSettings = [], quartileColors = [] } = data;
    const [customSettings, updateCustom] = useState(oldCustomSettings);
    const [active, enableSubmit] = useState(false);
    const { lang: defaultLang } = defaultSettings;
    const staticText = getStaticText(defaultLang);
    const { SITE_TEXT } = staticText;
    const { APPLY_SETTINGS, DIFF_HEAD, SETTINGS_SIGNIFICANCE } = SITE_TEXT || {};

    const [settingScoreValue, setSettingScoreValue] = useState('');
    // const [selectedCustom, setSelectedCustom] = useState('');
    // const [customDataSettings, setCustomDataSettings] = useState([]);
    const [error, setError] = useState('');

    const checkSelectedVal =
        customSettings.filter(item => item.title === 'OHI Resurvey Settings').map(item => item.selected_val) || [];

    const checkSelectVal =
        customSettings.filter(item => item.api_param === 'resurvey_custom_delta').map(item => item.selected_val) || [];
    // console.log(checkSelectVal, checkSelectedVal)

    const valueCheck = checkSelectedVal[0] === 'Custom delta';
    // console.log(valueCheck);
    // useEffect(() => {
    //     setCustomDataSettings(customSettings);
    // if (valueCheck) {
    //     setCustomDataSettings(customSettings);
    // } else {
    //     const updateCustomSettings = [...customSettings];
    //     updateCustomSettings.splice(4, 1);
    //     setCustomDataSettings(updateCustomSettings);
    // }
    // }, [selectedCustom, customSettings, valueCheck]);

    const setCustomData = (i, selected_val, param) => {
        const index = customSettings.findIndex(({ api_param }) => api_param === param);
        updateCustom([
            ...customSettings.slice(0, index),
            { ...customSettings[index], selected_val },
            ...customSettings.slice(index + 1),
        ]);
        const newValue = selected_val;
        enableSubmit(true);
        if (errorInfo) {
            resetError();
        }
        setSettingScoreValue(selected_val);

        if (newValue === '' || /^[0-9\b]+$/.test(newValue)) {
            const numValue = Number(newValue);
            if (numValue < 1 || numValue >= 99) {
                setError('Value must be between 1 to 99');
            } else {
                setError('');
            }
        }

        // if (
        //     selected_val === 'Custom delta' ||
        //     selected_val === 'Quartile movement' ||
        //     selected_val === 'Statistical significance'
        // ) {
        //     setSelectedCustom(selected_val);
        // }
    };

    const range = ['Less than -n', 'From -n to n', 'More than n'];

    const generateRanges = n => {
        const ranges = [
            { key: `(Less than -${n})`, values: [] },
            { key: `(More than ${n})`, values: [] },
            { key: `(-${n} to ${n})`, values: [] },
        ];
        return ranges;
    };

    const rangesHere = checkSelectVal[0] !== '' ? generateRanges(checkSelectVal) : [];

    const saveData = () => {
        const dataToSend = customSettings.reduce(
            (o, { api_param, selected_val }) => ({ ...o, [api_param]: selected_val }),
            {}
        );
        const { lang, report_type: oldReport } = dataToSend;
        const report_type = oldReport === 'percentile' ? 'percentile' : '';
        const updatedQColors = cloneDeep(quartileColors);
        for (let i = quartileColors.length; i < 6; i += 1) {
            updatedQColors.push(NO_BM_STYLE);
        }
        updateData({ ...dataToSend, quartileColors: JSON.stringify(updatedQColors) }, dataToSend, {
            report_type,
            lang: parseInt(lang, 10),
        });
        enableSubmit(false);
    };

    return (
        <div className="customization">
            {customSettings.map(({ title, description, options, selected_val, type = '', api_param }, index) => {
                return (
                    <section
                        key={title}
                        className={c(' customizeContent clearfix ', {
                            borderRemove: api_param === 'resurvey_settings',
                            // borderSet: api_param === 'resurvey_settings' && valueCheck,
                        })}
                    >
                        {api_param === 'resurvey_custom_delta' && valueCheck ? (
                            <div className="textRangeSettings">
                                <div style={{ width: '60%' }} />
                                <div style={{ width: '40%' }} dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        ) : null}
                        {api_param !== 'resurvey_custom_delta' ? (
                            <div className="textSettings">
                                <h3>{title}</h3>
                                <div className="descriptioPara" dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        ) : null}
                        {type === 'text' ? (
                            <>
                                {api_param === 'resurvey_custom_delta' && valueCheck ? (
                                    <>
                                        <div className="newRange">
                                            <div />
                                            <TextField
                                                value={selected_val}
                                                placeholder="Enter custom value(n)"
                                                onChange={({ target }) => setCustomData(index, target.value, api_param)}
                                                error={!!error}
                                                helperText={error}
                                                fullWidth
                                                className="select"
                                                variant="outlined"
                                            />
                                            <div className="rangeSignificance">
                                                <ul className="bottomList clearfix">
                                                    <li>{DIFF_HEAD} : </li>
                                                    {SETTINGS_SIGNIFICANCE.map(
                                                        ({ title: titles, bg: background }, i) => (
                                                            <li key={i}>
                                                                <span style={{ background }} />
                                                                {titles}
                                                                {checkSelectVal[0] !== '' ? (
                                                                    <p style={{ marginLeft: '3px' }}>
                                                                        {rangesHere[i].key}
                                                                    </p>
                                                                ) : (
                                                                    <p>{range[i]}</p>
                                                                )}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {api_param !== 'resurvey_custom_delta' ? (
                                    <TextField
                                        value={selected_val}
                                        placeholder={title}
                                        inputProps={{ 'data-testid': `Text${title}`, maxLength: 100 }}
                                        onChange={({ target }) => setCustomData(index, target.value, api_param)}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <div className="selectedScore">
                                <Select
                                    value={selected_val}
                                    IconComponent={ExpandMore}
                                    onChange={({ target }) => setCustomData(index, target.value, api_param)}
                                    className="select"
                                >
                                    {options.map(({ key, value: values = '' }) => (
                                        <MenuItem key={key} value={key} classes={{ root: 'customSelOptions' }}>
                                            {values || key}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {index === 1 && settingScoreValue === 'percentile' ? (
                                    <div className="benchmarkSettings">
                                        <div className="benchmarkPercentile">
                                            <InfoIcon className="benchImg" />
                                            <div className="benchInfo">
                                                Benchmark flagpole comparisons not available for percentile scores
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </section>
                );
            })}
            <div className="buttonContent clearfix">
                <Button data-testid="customSaveBtn" classes={{ root: c({ active }) }} onClick={saveData}>
                    {APPLY_SETTINGS}
                </Button>
            </div>
        </div>
    );
}

Customization.propTypes = {
    data: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
};

export default Customization;
